footer {
    margin-top: 15px;
    background: rgb(217, 152, 38);
    background-image: linear-gradient(90deg, #e80d8a 0%, #e70d8a 1%, #6d43a4 53%, #0670b9 99%);
    padding: 15px 0;
    font-family: "ITC-reg";

    .top-footer {
        padding: 35px 0;

        .Social-box {
            .Social-list {
                li {
                    color: $color2;
                    font-size: 15px;
                    font-family: "ITC-med";

                    span {
                        font-family: "ITC-reg";
                        display: inline-block;
                    }
                }

                &.add-list {
                    li {
                        span {
                            display: block;
                        }

                        .address-border {
                            margin: 5px 0;
                            border-bottom: 1px rgb(216, 214, 214) solid;
                        }
                    }
                }
            }
        }
    }

    .bottom-footer {
        padding: 0;

        .footer-logo {
            text-align: right;
        }

        .footer-menu {
            li {
                display: inline-block;
                width: auto;
                margin-bottom: 0;
                margin-right: 35px;

                a {
                    color: #414042;
                    font-size: 14px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .footer-col {
            &:last-child {
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .footer-menu {
        li {
            width: auto;

            a {
                color: #414042;
                letter-spacing: 0.15px;
                font-size: 15px;
            }
        }
    }

    .clf-footer-logo {
        margin-bottom: 15px;

        svg {
            width: 100px;
        }
    }

    .clf-footer-inner {
        p {
            color: var(--$white);
            padding-top: 10px;

            span {
                margin-right: 10px;
            }
        }
    }

    ul.social-listing {
        display: flex;
        gap: 10px;
        padding-top: 15px;
        justify-content: center;
    }

    .clf-footer-link {
        .clf-footer-title {
            color: var(--$white);
            font-size: 18px;
            font-weight: var("--$fw600");
            font-family: "ITC-med";
        }

        ul {
            padding-left: 15px;

            li {
                color: var(--$white);
                list-style: none;
                padding-top: 10px;

                a {
                    color: var(--$white);
                }
            }
        }
    }

    .clf-footer-text {
        color: var(--$white);
        line-height: 1.5;
    }

    .clf-footer-title {
        color: var(--$white);
        font-size: 18px;
        font-weight: var("--$fw600");
        font-family: "ITC-med";
        padding-top: 15px;
    }

    .copyright-text {
        padding-top: 15px;
        color: #fff;
    }
}

// Responsive
@include responsive(md) {
    footer {
        .bottom-footer {
            text-align: center;

            .order-1.order-sm-0 {
                order: 1;
            }

            .footer-col {
                padding: 0;
            }

            .footer-menu {
                li {
                    width: 45%;

                    a {
                        font-size: 11px;
                    }
                }
            }

            .clf-footer-text {
                padding-top: 25px;
            }
        }
    }
}

@include responsive(sm) {
    footer {
        .footer-col {
            padding: 0;
        }

        .footer-menu {
            li {
                width: 45%;

                a {
                    font-size: 11px;
                }
            }
        }
    }
}