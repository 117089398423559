.about-clefill-section {
    padding-top: 100px;
}
.cls-abt-banner {
    position: relative;
    margin-bottom: 50px;
    img {
        width: 100%;
    }
    .cls-about-banner-content {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background: rgb(0 0 0 / 50%);
        justify-content: center;
        font-family: "Nunito", sans-serif;
        .text-hading {
            p {
                font-size: 70px;
                color: white;
            }
        }
    }
}
.cls-abt-bg-video {
    font-family: "Nunito", sans-serif;
    margin-bottom: 50px;
    .col-10.cls-video-center {
        margin: 0 auto;
        display: flex;
        align-items: center;
        .cls-abt-banner-video-wrapper {
            width: 60%;
            padding-right: 35px;
            position: relative;
            span.cls-play-button {
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-size: 75px;
                color: #fff;
            }
            video {
                width: 100%;
            }
        }
        .cls-abt-banner-text {
            width: 40%;
            padding-left: 35px;
            .cls-abt-content-box {
                .title h3 {
                    font-size: 60px;
                    color: $black;
                }
                .cls-abt-text {
                    p {
                        color: $black;
                    }
                }
                .cls-abt-box-button {
                    margin-top: 40px;
                    button.btn {
                        border-radius: 24px;
                        background-image: linear-gradient(90deg, #f4801f 1%, #e80d8a 53%, #0670b9 99%);
                        padding: 15px 35px;
                        cursor: pointer;
                        color: $white;
                    }
                }
            }
        }
    }
}
.cls-slides-blog {
    .cls-abt-content-box {
        padding: 15px;
        font-family: "Nunito", sans-serif;
        text-align: center;
        .title {
            h3 {
                font-size: 40px;
            }
            p {
                padding: 10px 0;
            }
        }
    }
}

@include responsive(lg) {
    .about-clefill-section {
        padding-top: 0px;
        .cls-abt-banner {
            margin-bottom: 30px;
            img {
                width: 100%;
                min-height: 300px;
                object-fit: cover;
            }
            .cls-about-banner-content {
                .text-hading {
                    p {
                        font-size: 30px;
                    }
                }
            }
        }
        .cls-abt-bg-video {
            .col-10.cls-video-center {
                margin: 0 auto;
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                flex-direction: column;
                .cls-abt-banner-video-wrapper {
                    width: 100%;
                    padding-right: 0px;
                    margin-bottom: 15px;
                }
                .cls-abt-banner-text {
                    width: 100%;
                    padding-left: 0;
                    .title {
                        h3 {
                            font-size: 35px;
                        }
                    }
                }
            }
        }
        .cls-responsible-content {
            .cls-center-col {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
