.banner-textbox {
	text-align: center;

	.btn-box {
		margin-bottom: 0;
		width: 100%;
		display: block;

		.link-btn {
			border: 1px solid #414042;
			border-radius: 50px;
			background: #fff;
			display: inline-block;
			text-align: center;
			color: #414042;
			transition: 0.4s all ease-in-out;
			padding: 7px 35px;
			font-family: "Nunito", sans-serif;
		}
	}
}

.banner-btm-txt .text-gradiant.banner-head {
	font-size: 32px;
	display: inline-block;
}

.info-section .info-area h3.big-txt {
	font-size: 72px;
}

.banner-btm-txt {
	.text-gradiant {
		font-size: 24px;
		margin-bottom: 10px;
	}

	p {
		font-size: 18px;
		color: rgba(65, 64, 66, 0.7215686275);
		font-family: "ITC-med";
		margin-bottom: 15px;
	}

	.btn-box {
		margin-bottom: 0;
		padding-right: 0;

		.link-btn {
			@include prefix(transition, 0.4s all ease-in-out);

			&:hover {
				background: $color2;
				color: $white;
			}
		}
	}
}

.pro-col {
	// display: flex;
	// justify-content: center;
	// position: relative;
	display: inline-block;
	// padding: 0 15px !important;

	figure {
		width: 320px;
		overflow: hidden;
		margin: 0 auto;
		max-width: 100%;
		@include prefix(border-radius, 15px);

		img {
			width: 100%;
			height: 100%;
		}
	}

	// .pro-over-btn {
	//   position: absolute;
	//   top: 50%;
	//   background-color: #fff;
	//   border-radius: 10px;
	//   width: 165px;
	//   height: 52px;
	//   display: flex;
	//   align-items: center;
	//   justify-content: center;
	//   color: #414143;
	//   font-family: ITC-reg;
	//   font-size: 24px;
	//   @include prefix(transform, translateY(-50%));
	// }
}

.pro-over-btn {
	background-color: #fff;
	// border-radius: 10px;
	width: 130px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #414143;
	font-family: ITC-reg;
	font-size: 20px;
}

.pro-over-btn-wrap {
	// position: absolute;
	// top: 50%;
	border: 2px solid #414042;
	border-image-slice: 100%;
	border-image-source: none;
	border-image-source: linear-gradient(90deg,
			rgb(243, 121, 40) 0%,
			rgb(234, 25, 129) 30%,
			rgb(134, 57, 157) 70%,
			rgb(42, 96, 173) 100%);
	border-image-slice: 1;
	// @include prefix(transform, translateY(-50%));
	width: 134px;
	margin: 15px auto 0 auto;
}

.text-class {
	display: block;
}

.font-box {
	font-size: 16px;
	font-family: "ITC-med";
}

.mobile-box {
	display: none;
	margin-bottom: 10px;
}

.fill-linkbox {
	display: flex;

	.fill {
		margin-right: 7px;
		font-size: 11px !important;
		text-decoration: underline !important;

		&:nth-child(2) {
			margin-left: 7px;
		}
	}
}

.list-items {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}

	.line-div {
		position: relative;
		display: flex;
		align-items: center;

		.arrow-div {
			position: relative;
			margin-right: 7px;

			&::after {
				content: ">";
				font-size: 15px;
				color: #000;
				margin-left: 7px;
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}

.marquee {
	width: 100%;
	margin: 22px auto 0px;
	overflow: hidden;
	box-sizing: border-box;
	padding: 15px 0;
	display: flex;
	background-image: linear-gradient(90deg, #e80d8a 0%, #e70d8a 1%, #6d43a4 53%, #0670b9 99%);
	align-items: center;

	p {
		span.text-gradiant1 {
			margin-right: 20px;
			color: #ffffff;
			font-family: "Nunito", sans-serif;
			font-weight: bold;
			text-transform: capitalize;
		}
	}
}

.marquee p {
	display: flex;
	width: max-content;
	font-family: "ITC-bold";
	padding-left: 0;
	word-spacing: 50px;
	font-size: 20px;
	will-change: transform;
	margin-left: 30px;
	// color: #717070;
	// background: -webkit-linear-gradient(90deg, rgb(243, 121, 40) 0%, rgb(234, 25, 129) 30%, rgb(134, 57, 157) 70%, rgb(42, 96, 173) 100%);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	animation: marquee 15.6555s linear infinite;

	span {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}
}

//   .marquee span:hover {
//     animation-play-state: paused
//   }
@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-100%, 0);
	}
}

/* Respect user preferences about animations */

@media (prefers-reduced-motion: reduce) {
	.marquee span {
		animation-iteration-count: 1;
		animation-duration: 0.01;
		/* instead of animation: none, so an animationend event is 
       * still available, if previously attached.
       */
		width: auto;
		padding-left: 0;
	}
}

.cls-product-culomn {
	padding: 40px 80px !important;

	.cls-catogary-box {
		background: #f8f8f8;
		height: 100%;
		text-align: left;
		padding: 25px;

		.cls-box-img {
			width: 160px;
			margin: 0 auto 15px;
			text-align: center;

			img {
				border-radius: 15px;
			}
			video {
				border-radius: 15px;
			}
		}

		.cls-box-right {
			font-family: "Nunito", sans-serif;

			.cls-box-title span {
				color: #414042;
				font-size: 35px;
				font-family: "Nunito", sans-serif;
			}

			.cls-box-content p {
				color: rgba(65, 64, 66, 0.7215686275);
				font-size: 16px;
				line-height: 24px;
			}

			.cls-box-button {
				margin-top: 15px;

				.btn {
					border: 1px solid #3d3b3e;
					border-radius: 50px;
					font-family: "Nunito", sans-serif;
					padding: 7px 35px;
				}
			}
		}
	}
}

.cls-gradient-banner {
	.banner-section {
		.banner-area {
			padding: 0;
			background-image: linear-gradient(to right bottom,
					#ff94fa,
					#efadff,
					#e4c2ff,
					#dfd4ff,
					#e3e3ff,
					#e2e4ff,
					#e1e6ff,
					#e0e7ff,
					#d2ddff,
					#c4d2ff,
					#b6c8ff,
					#a8beff);

			img {
				margin: 0 auto;
			}
		}
	}
}

.banner-section {
	text-align: center;

	&.overlay {
		position: relative;
		overflow: hidden;
		z-index: 1;

		&::before {
			content: "";
			background-color: rgba(78, 78, 78, 0.4);
			width: 100%;
			height: 100%;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.banner-area {
		background-color: $white;
		text-align: left;
		padding: 250px 15px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		a {
			display: inline-block;
			width: 120px;
			height: 35px;
			margin-bottom: 20px;
		}

		p {
			font-size: 18px;
			color: #fff;
			font-family: "ITC-med";
			margin-bottom: 15px;
			width: 70%;
		}

		.btn-box {
			margin-bottom: 0;

			.link-btn {
				border: 1px solid #fff;
				border-radius: 3px;
				background: transparent;
				width: 100px;
				height: 30px;
				display: inline-block;
				text-align: center;
				line-height: 28px;
				font-size: 15px;
				font-family: "ITC-reg";
				color: #fff;
				margin-bottom: 0;
				@include prefix(transition, 0.4s all ease-in-out);

				&:hover {
					background: $color2;
					color: $white;
				}
			}
		}
	}

	.banner-imgbox {
		text-align: center;

		.swiper {
			padding-bottom: 40px;
		}
	}

	.banner-box {
		position: fixed;
		max-width: 768px;
		top: 55px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-color: $color1;
		padding: 10px 0;
		z-index: 11111;
		display: flex;
		justify-content: center;
	}
}

.btn-box {
	max-width: 343px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;

	&.mobile-btns {
		margin: inherit;
		justify-content: center !important;
		max-width: 100%;
		padding-right: 0;
		width: 100%;
	}

	.outer-line {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $color2;
		cursor: pointer;
		border-radius: 3px;
		background: transparent;
		width: 165px;
		height: 31px;
		overflow: hidden;
		@include prefix(transition, 0.4s all ease-in-out);

		a {
			width: 161px;
			height: 27px;
			// display: inline-block;
			// text-align: center;
			// vertical-align: middle;
			// line-height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color2;
			font-size: 14px;
			background: $color1;
			font-family: "ITC-reg";
			text-transform: capitalize;
			position: relative;
			padding: 8px 3px 3px 3px;
		}

		&:not(:last-child) {
			margin-right: 13px;
		}

		&:hover {
			background: -moz-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: -webkit-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
		}

		&.active {
			background: -moz-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: -webkit-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
		}
	}
	.outer-line-web {
		display: flex;
		justify-content: center;
		padding: 3px 3px 3px 3px;
		align-items: center;
		border: 1px solid $color2;
		cursor: pointer;
		border-radius: 3px;
		background: transparent;
		// width: 165px;
		// height: 31px;
		width: 200px;  // Increased width
		height: 45px;  // Increased height
		overflow: hidden;
		@include prefix(transition, 0.4s all ease-in-out);

		a {
			width: 196px;
			height: 41px;
			// display: inline-block;
			// text-align: center;
			// vertical-align: middle;
			// line-height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color2;
			font-size: 14px;
			background: $color1;
			font-family: "ITC-reg";
			text-transform: capitalize;
			position: relative;
			padding: 8px 3px 3px 3px;
		}

		&:not(:last-child) {
			margin-right: 13px;
		}

		&:hover {
			background: -moz-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: -webkit-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
		}

		&.active {
			background: -moz-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: -webkit-linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
			background: linear-gradient(90deg,
					rgba(243, 121, 40, 1) 0%,
					rgba(234, 25, 129, 1) 30%,
					rgba(134, 57, 157, 1) 70%,
					rgba(42, 96, 173, 1) 100%);
		}
	}

	.active {
		background: -moz-linear-gradient(90deg,
				rgba(243, 121, 40, 1) 0%,
				rgba(234, 25, 129, 1) 30%,
				rgba(134, 57, 157, 1) 70%,
				rgba(42, 96, 173, 1) 100%);
		background: -webkit-linear-gradient(90deg,
				rgba(243, 121, 40, 1) 0%,
				rgba(234, 25, 129, 1) 30%,
				rgba(134, 57, 157, 1) 70%,
				rgba(42, 96, 173, 1) 100%);
		background: linear-gradient(90deg,
				rgba(243, 121, 40, 1) 0%,
				rgba(234, 25, 129, 1) 30%,
				rgba(134, 57, 157, 1) 70%,
				rgba(42, 96, 173, 1) 100%);
	}
}

.product-section {
	padding: 35px 0 0;

	&.mobile-product {
		.product-box {
			figure {
				width: 90px;
				height: 104px;
				margin-right: 10px;

				img {
					height: 100%;
					width: 100%;
				}
			}
		}
	}

	.product-box {
		background-color: $white;
		border-radius: 10px;
		@include prefix(box-shadow, 0px 0px 10px #0000003e);
		padding: 12px 18px;
		height: 100%;
	}

	.product-info {
		width: calc(100% - 105px);
		@extend .d-flex;
		@extend .align-items-start;
		@extend .flex-column;

		h3 {
			font-size: 20px;
			font-family: "ITC-bold";
			color: $color2;
			margin-bottom: 10px;
			line-height: 1;
		}

		p {
			color: $color3;
			font-size: 12px;
			margin-bottom: 8px;
			font-family: "ITC-reg";
			//height: 46px;
			overflow: hidden;

			&.height {
				font-size: 14px;
			}
		}

		a {
			font-size: 12px;
			font-family: "ITC-bold";
			color: $color2;
			display: inline-block;

			//margin: auto 0 8px;
			i {
				display: inline-block;
				width: 6px;
				height: 16px;
				vertical-align: text-bottom;
				margin-left: 10px;
			}
		}

		.rc-input-number-handler {
			a {
				font-family: auto !important;
				line-height: inherit;
				padding: 0 !important;
				min-height: 0 !important;
			}
		}

		// .font-auto {
		//     font-family: auto !important;        }
	}
}

.info-section {
	// padding: 20px 0;
	padding: 60px 0 20px 0;

	.info-area {
		h3 {
			font-size: 20px;
			font-family: "ITC-bold";
			color: $color2;
			margin-bottom: 10px;
			// line-height: 1;
		}

		p {
			color: $color3;
			font-size: 12px;
			margin-bottom: 8px;
			font-family: "ITC-reg";
			//             .text-gradiant {
			//                 background: -webkit-linear-gradient(90deg, rgb(243, 121, 40) 0%, rgb(234, 25, 129) 30%, rgb(134, 57, 157) 70%, rgb(42, 96, 173) 100%);
			//   background-clip: border-box;
			// background-clip: border-box;
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
			//             }
		}

		a {
			font-size: 12px;
			font-family: "ITC-bold";
			color: $color2;
			display: inline-block;

			i {
				display: inline-block;
				width: 6px;
				height: 16px;
				vertical-align: text-bottom;
				margin-left: 10px;
			}
		}
	}

	.info-mainbox {
		&:not(:last-child) {
			margin-bottom: 50px;
		}
	}

	.info-box {
		margin-bottom: 10px;

		figure {
			position: relative;
			margin-bottom: 20px;
			border-radius: 10px;
			overflow: hidden;

			&:last-child {
				margin-bottom: 5px;
			}

			.play-btn {
				width: 51px;
				height: 51px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				overflow: hidden;
				background-color: rgba(255, 255, 255, 0.5);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				cursor: pointer;
				@include prefix(backdrop-filter, blur(10px));

				&:before {
					content: "";
					width: 17px;
					height: 21px;
					display: block;
					background-repeat: no-repeat;
					position: absolute;
				}
			}
		}
	}

	.banner-imgbox {
		.swiper {
			padding-bottom: 0px;

			figure.silder-img {
				width: 100%;
				height: 100%;
				border-radius: 0;
				margin: auto;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.swiper-button-next,
			.swiper-button-prev {
				top: unset;
				bottom: 10px;
				width: 20px;
				height: 20px;

				&:after {
					font-size: 20px;
				}
			}

			.swiper-button-next {
				right: 43%;
			}

			.swiper-button-prev {
				left: 43%;
			}

			.swiper-pagination {
				bottom: 20px;
			}
		}
	}
}

.model-box {
	background-color: $color4;
	color: $black;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	padding: 5px 15px;
	width: 100%;
	min-height: 100vh;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 1111111;

	.video-box {
		height: 29%;
		width: 80%;
		display: inline-block;
		position: absolute;
		margin: 0 auto;
		vertical-align: middle;
		text-align: center;
		padding-top: 30%;

		iframe {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
		}
	}

	.model-close-btn {
		cursor: pointer;
		font-size: 30px;
		color: $black;
		font-family: "ITC-reg";
		position: absolute;
		top: 90px;
		right: 0;
		left: 0;
		margin: 0 auto;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		overflow: hidden;
		background-color: #f7f7f7;
		z-index: 111;
	}
}

.video-model {
	&.model-open {
		display: flex;
	}
}

.location-model {
	.location-box {
		max-width: 414px;
		min-width: 343px;
		min-height: 360px;
		padding: 55px 40px;
		border-radius: 10px;
		background-color: $white;
		position: relative;
		text-align: center;
		@include prefix(box-shadow, 0px 3px 10px #00000040);

		h1 {
			font-size: 32px;
			font-family: "ITC-hvy";
			color: #1e1e1e;
			line-height: 1;
			margin-bottom: 20px;
		}

		p {
			font-family: "ITC-reg";
			font-size: 15px;
			color: rgba(65, 64, 66, 0.52);
			width: 70%;
			margin: 0 auto 20px;
		}

		form {
			.form-grp {
				position: relative;
				margin-bottom: 23px;

				.input-box {
					background-color: rgba(216, 216, 216, 0.16);
					width: 100%;
					height: 45px;
					border-radius: 10px;
					font-family: "ITC-reg";
					padding: 0 20px 0 40px;
					border: 1px solid transparent;
					border-image-slice: 1;

					&:hover {
						border-radius: 0;
						border-image-source: -moz-linear-gradient(90deg,
								rgba(243, 121, 40, 1) 0%,
								rgba(234, 25, 129, 1) 30%,
								rgba(134, 57, 157, 1) 70%,
								rgba(42, 96, 173, 1) 100%);
						border-image-source: -webkit-linear-gradient(90deg,
								rgba(243, 121, 40, 1) 0%,
								rgba(234, 25, 129, 1) 30%,
								rgba(134, 57, 157, 1) 70%,
								rgba(42, 96, 173, 1) 100%);
						border-image-source: linear-gradient(90deg,
								rgba(243, 121, 40, 1) 0%,
								rgba(234, 25, 129, 1) 30%,
								rgba(134, 57, 157, 1) 70%,
								rgba(42, 96, 173, 1) 100%);
					}

					&:focus {
						border-radius: 0;
						border-image-source: -moz-linear-gradient(90deg,
								rgba(243, 121, 40, 1) 0%,
								rgba(234, 25, 129, 1) 30%,
								rgba(134, 57, 157, 1) 70%,
								rgba(42, 96, 173, 1) 100%);
						border-image-source: -webkit-linear-gradient(90deg,
								rgba(243, 121, 40, 1) 0%,
								rgba(234, 25, 129, 1) 30%,
								rgba(134, 57, 157, 1) 70%,
								rgba(42, 96, 173, 1) 100%);
						border-image-source: linear-gradient(90deg,
								rgba(243, 121, 40, 1) 0%,
								rgba(234, 25, 129, 1) 30%,
								rgba(134, 57, 157, 1) 70%,
								rgba(42, 96, 173, 1) 100%);
					}
				}

				&::before {
					content: "";
					width: 15px;
					height: 18px;
					display: block;
					background-repeat: no-repeat;
					position: absolute;
					top: 15px;
					left: 15px;
				}
			}

			span {
				display: block;
				font-size: 15px;
				padding-bottom: 17px;
				border-bottom: 1px solid rgba(245, 246, 248, 1);
				font-family: "ITC-med";
				margin-bottom: 22px;
			}
		}

		.model-close-btn {
			background-color: transparent;
			left: unset;
			top: 16px;
			right: 16px;
		}
	}

	&.model-open {
		display: flex;
	}
}

.links-btn {
	min-width: 196px;
	height: 47px;
	border: 1px solid rgba(65, 64, 66, 1);
	border-radius: 10px;
	font-size: 16px;
	font-family: "ITC-med";
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: $color2;
	background-color: transparent;
	cursor: pointer;
	@include prefix(transition, 0.4s all ease-in-out);

	&:hover {
		background-color: $color2;
		color: $white;
	}
}

.fill-model {
	.text-box {
		max-width: 370px;
		min-height: 235px;
		padding: 35px 30px;
		border-radius: 10px;
		background-color: $white;
		position: relative;
		text-align: center;
		@include prefix(box-shadow, 0px 3px 10px #00000040);

		h1 {
			font-size: 35px;
			font-family: "ITC-hvy";
			color: #1e1e1e;
			line-height: 1;
			margin-bottom: 20px;
		}

		p {
			font-family: "ITC-reg";
			font-size: 15px;
			color: rgba(65, 64, 66, 0.52);
		}

		.model-close-btn {
			background-color: transparent;
			left: unset;
			top: 16px;
			right: 16px;
		}
	}

	&.model-open {
		display: flex;
	}
}

.cls-social-media {
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 99;
	color: #ffffff;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40px;

	img {
		margin-bottom: 15px;
	}
}

// Responsive

@media (max-width: 1199px) {
	.cls-right-col {
		margin-top: 30px;
	}
}

@include responsive(xs) {
	.mobile-box {
		display: grid;

		.swiper {
			display: flex;
			width: 100%;
		}
	}

	.tab-box {
		display: none;
	}
}

@include responsive(xs) {
	.banner-section {
		.banner-area {
			padding: 135px 0px;
			background-size: 100%;

			p {
				width: 100%;
			}
		}
	}

	.product-section {
		.product-col {
			padding: 0;
		}
	}

	.info-section {
		.banner-imgbox {
			.swiper {
				figure {
					&.silder-img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.info-mainbox:nth-child(even) {
			flex-direction: column-reverse;
		}

		.info-box {
			figure {
				border-radius: 10px;
				overflow: hidden;
			}
		}

		.info-col {
			padding: 0;
		}

		.info-mainbox {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	.product-col {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	.info-section {
		padding-bottom: 0;
		padding-top: 15px;
	}
}

.cls-usp-box {
	margin-top: 30px;
	background-image: linear-gradient(90deg, #e80d8a 0%, #e70d8a 1%, #6d43a4 53%, #0670b9 99%);
	padding: 25px;
	font-family: "Nunito", sans-serif;
	color: $white;

	span {
		font-size: 22px;
		padding-left: 10px;
		vertical-align: middle;
	}
}

.cls-responsible-content {
	font-family: "Nunito", sans-serif;

	.cls-content-column {
		background: #f8f8f8;
		height: 100%;
		padding: 50px;
	}
}

.cls-prodects-section {
	background-color: #f6f5f5;
	font-family: "ITC-med";
	padding-bottom: 0px;
	margin-bottom: 60px !important;
	padding-left: 80px !important;
	padding-right: 80px !important;

	.cls-hading {
		padding: 20px 0;

		h2 {
			text-align: center;
			font-family: "Nunito", sans-serif;
			font-weight: 600;
		}
	}

	.swiper {
		height: 100%;
		padding-bottom: 50px;

		.cls-product-box {
			background: $white;
			text-align: center;
			padding: 25px;
			font-family: "Nunito", sans-serif;
			display: flex;
			height: auto;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
			border-radius: 5px;

			.product-text {
				padding-top: 15px;
				margin-top: 0;

				.title {
					color: #1a67b5;
					font-family: "Nunito", sans-serif;
					min-height: 60px;
				}

				.price {
					color: #414042;
					padding: 15px 0;
				}
			}

			.product-add-btn {

				.cls-btn-box {
					background: $white;
					border: 1px solid #3d3b3e;
					padding: 9px 30px;
					border-radius: 50px;
					display: inline-block;
					text-align: center;
					cursor: pointer;
					transition: all 0.5s ease-in-out;
				}
			}

			.product-img {
				height: 150px;

				img {
					height: 100%;
				}
			}

			.rc-input-number {
				display: flex;
				margin: 15px auto auto;
				justify-content: center;
				margin-top: auto;
			}
		}
	}
}

@include responsive(s) {
	.profile-banner-section.btn-box-mob .btn-box {
		justify-content: center !important;
		padding: 0 15px;
	}

	.btn-box {
		padding: 0 15px 0 0;
		justify-content: flex-start !important;

		.outer-line {
			width: 138px;

			a {
				width: 134px;
			}
		}
	}
}

@include responsive(lg) {
	.cls-hero-banner-home-page {
		padding: 0 !important;
		font-family: "Nunito", sans-serif;

		.swiper {
			padding-top: 6px;
			z-index: 1;
			display: flex;
			flex-direction: column-reverse;

			.swiper-pagination {
				position: static;

				.swiper-pagination-bullet-active {
					background: #000;
				}
			}

			.banner-btm-txt {
				min-height: 220px;

				.banner-area {
					background: #f5f5f5;
					padding: 0px;

					.banner-textbox {
						padding: 15px;
						min-height: 200px;

						p {
							font-family: "Nunito", sans-serif;
							font-size: 16px;

							.banner-head {
								font-size: 28px;

								span {
									font-size: 28px;
								}
							}
						}
					}
				}
			}
		}
	}

	.cls-bg-video {
		font-family: "Nunito", sans-serif;
		position: relative;

		.cls-banner-video-wrapper {
			video {
				width: 100%;
				height: 100%;
			}
		}

		.cls-banner-text {
			padding: 15px;
			text-align: center;
			background: rgba(0, 0, 0, 0.6);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;

			.cls-content-box {
				.title {
					padding-bottom: 15px;

					h3 {
						font-size: 35px;
						color: #fff;
						display: flex;
						justify-content: center;

						p {
							font-size: 35px;
						}
					}
				}

				.cls-text {
					p {
						color: #fff;
					}
				}

				.cls-box-button {
					margin-top: 15px;

					button.btn {
						border-radius: 24px;
						background-image: linear-gradient(90deg, #f4801f 1%, #e80d8a 53%, #0670b9 99%);
						padding: 9px 35px;
						color: #ffffff;
					}
				}
			}
		}
	}

	.cls-usp-box {
		padding: 10px 0;

		.col-usp-blog {
			padding: 15px 0;

			img {
				width: 45px;
			}

			span {
				font-size: 18px;
			}
		}
	}

	.cls-responsible-content {
		.cls-content-column {
			padding: 30px;

			.title {
				padding-bottom: 15px;

				h3 {
					font-size: 30px;
				}
			}

			.cls-list {
				span {
					padding: 5px 0;
					font-size: 16px;
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.cls-bg-video {
		font-family: "Nunito", sans-serif;

		.cls-banner-video-wrapper {
			video {
				width: 100%;
			}
		}

		.cls-banner-text {
			padding: 15px;
			text-align: left;

			.cls-content-box {
				.title {
					padding-bottom: 15px;

					h3 {
						font-size: 20px;
						color: #fff;
						display: flex;
						justify-content: start;

						p {
							font-size: 20px;
							color: #fff;
						}
					}
				}

				.cls-box-button {
					margin-top: 15px;

					button.btn {
						border-radius: 24px;
						background-image: linear-gradient(90deg, #f4801f 1%, #e80d8a 53%, #0670b9 99%);
						padding: 10px 40px;
						color: #ffffff;
					}
				}
			}
		}
	}

	.cls-usp-box {
		padding: 25px 0;

		.col-usp-blog {
			padding: 15px 0;

			img {
				width: 45px;
			}

			span {
				font-size: 17px;
			}
		}
	}

	.cls-responsible-content {
		.cls-content-column {
			padding: 30px;

			.title {
				padding-bottom: 15px;

				h3 {
					font-size: 30px;
				}
			}

			.cls-list {
				span {
					padding: 5px 0;
					font-size: 16px;
				}
			}
		}
	}
}

@media only screen and (max-width: 475px) {
	.cls-prodects-section {
		.swiper {
			height: 100%;
			padding-bottom: 50px;

			.cls-product-box {
				background: $white;
				text-align: center;
				padding: 25px;
				font-family: "Nunito", sans-serif;
				display: flex;
				height: auto;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
				border-radius: 5px;

				.product-text {
					padding-top: 15px;
					margin-top: 0;

					.title {
						color: #1a67b5;
						font-family: "Nunito", sans-serif;
						min-height: unset;
					}
				}
			}
		}
	}

	.cls-hero-banner-home-page {
		.swiper {
			.banner-btm-txt {
				.banner-area {
					.banner-textbox {
						min-height: 240px;
					}
				}
			}
		}
	}

}

@media only screen and (max-width: 375px) {
	.cls-bg-video {
		.cls-banner-text {
			.cls-content-box {
				.title {
					padding-bottom: 12px;
				}

				.cls-text {
					p {
						font-size: 12px;
					}
				}
			}
		}
	}
}