.refund-section{
    padding-top: 30px;
    .address_form{
        .payment-list{
            .add-radio-list{
                h4{
                    margin-bottom: 15px;
                    color: $color2;
                    font-family: 'ITC-bold';
                    line-height: 1;
                }
                .radio-grp  {
                    label{
                        padding: 0px 0 0 5px;
                        &::before{
                            left: auto;
                            right: 6px;
                        }
                        &::after{
                            left: auto;
                            right: 0;
                        }
                    }
                    input{
                        &:checked{
                            &+ label .input-list{
                                display: inline-block;
                            }
                        }
                    }
                }
                .input-list{
                    margin-top: 15px;
                    display:none ;
                    margin-bottom: 0;
                    width: 100%;
                    li{
                        margin-bottom: 0;
                    }
                }
                .floating{
                    &::before{
                        display: none;
                    }
                    label{
                        border-radius: 0;
                        background-color: transparent;
                        &::before{
                            top: 5px;
                            left: 4px;
                            right: auto;
                            height: auto;
                            width: auto;
                            opacity: 1;
                            background-color: transparent;
                            border-radius: 0;
                        }
                        &::after{
                            left: 0;
                            top: 100%;
                            right: auto;
                            border-radius: 0;
                            border: none;
                        }
                    }
                    .floating__input{
                        opacity: 1;
                        position: relative;
                        padding: 13px 20px 0 10px;
                    }
                }
            }
        }
    }
    &.return-section{
        .return-info{
            figure{
                width: 37px;
                height: 53px;
                margin-right: 14px;
            }
            .product-add-info{
                h4{
                    font-size: 14px;
                    color: #717171;
                    margin-bottom: 5px;
                }
                .total-price {
                    h6{
                        font-size: 12px;
                        color: #717171;
                        font-family: 'ITC-reg';
                    }
                }
            }
        }
        .add-btn{
            .btn-add{
                font-size: 18px;
            }
        }
        .input-list{
            margin-bottom: 30px;
            li{
                .form-grp{
                    &::before{
                        display: none;
                    }
                }
            }
            .floating {
                .floating__input{
                    padding: 0 20px 0 10px;
                    font-family: 'ITC-reg';
                    color: #6A6A6A;
                }
            }
        }
    }
}

.return-section{
    .address_form{
        min-height: auto;
        height: 100%;
        .payment-list{
            min-height: auto;
            height: 100%;
        }
    }
}

.Successful-model{
    .order-section{
        .order-screen{
            min-height: 380px;
            h2{
                width: 80%;
            }
            p{
                width: 100%;
            }
            &.successful-box{
                padding: 44px 35px;
                min-height: 365px;
                h2{
                    width: 100%;
                }
            }
        }
    }
}

.css-b62m3t-container{
    .css-1s2u09g-control{
        background-color: rgba(216, 216, 216, 0.16);
        border-radius: 10px;
        border: none;
        min-height: 45px;
        font-size: 14px;
            color: #6A6A6A;
            font-family: 'ITC-reg';
    }
    .css-1hb7zxy-IndicatorsContainer{
        .css-1okebmr-indicatorSeparator{
            display: none;
        }
    }
    
    .css-tlfecz-indicatorContainer{
        color: #7D7D7D;
    }
    .css-6j8wv5-Input{
            font-size: 14px;
            color: #6A6A6A;
            font-family: 'ITC-reg';
        input{
            font-size: 14px;
            color: #6A6A6A;
            font-family: 'ITC-reg';
        }
    }
    .css-1pahdxg-control{
        border: none;
        background-color: rgba(216, 216, 216, 0.16);
        min-height: 45px;
        @include prefix (box-shadow, none);
        font-size: 14px;
        color: #6A6A6A;
        font-family: 'ITC-reg';
        &:hover{
            border-color: transparent;
        }
        &:focus{
            border-color: transparent;
        }
    }
    #react-select-3-listbox{
        font-size: 14px;
        color: #6A6A6A;
        font-family: 'ITC-reg';
    }
    .css-qc6sy-singleValue{
        font-size: 14px;
        color: #6A6A6A;
        font-family: 'ITC-reg';
    }
    .css-2613qy-menu{
        font-family: 'ITC-reg' !important;
    }
}

@include responsive(s){
    .Successful-model{
        .order-section{
            .order-screen{
                min-height: 335px;
                padding: 30px 15px;
                h2{
                    width: 100%;
                }
                p{
                    width: 100%;
                }
                &.successful-box{
                    padding: 44px 20px;
                    h2{
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .refund-section{
        &.return-section{
            .return-info{
                .product-add-info{
                    h4{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}