.order-list-section {
    padding: 20px 0;

    .order-list-box {
        .icon-box {
            text-align: right;
            margin-bottom: 25px;

            i {
                margin-right: 8px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .order-list {
            li {
                background-color: #fff;
                padding: 12px 13px;
                min-height: 130px;
                width: 100%;
                @include prefix(box-shadow, 0px 0px 10px #0000003d);
                border-radius: 15px;
                margin-bottom: 20px;

                .order-title {
                    margin-bottom: 10px;

                    h6 {
                        font-size: 14px;
                        color: $color2;
                        line-height: 1;
                        font-family: "ITC-med";
                    }

                    .green {
                        color: #38d00b;
                    }
                }

                .order-info {
                    h4 {
                        font-size: 14px;
                        color: $color2;
                        margin-bottom: 7px;
                        line-height: 1;
                        font-family: "ITC-bold";
                    }

                    .order-info-list {
                        li {
                            font-size: 12px;
                            font-family: "ITC-med";
                            margin-bottom: 7px;
                            color: #717171;
                            padding: 0;
                            min-height: 100%;
                            width: auto;
                            border-radius: 0;
                            @include prefix(box-shadow, none);

                            span {
                                display: inline-block;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .order-rightbox {
                    width: calc(100% - 200px);
                    text-align: right;

                    .hover-text-white {
                        a {
                            color: black;
                        }

                        &:hover a {
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        .add-btn {
            .btn-add {
                min-width: 120px;
                margin-right: 0;
                margin-bottom: 8px;
                display: block;
                margin-left: auto;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.help-add-section {
        .order-list-box {
            .order-list {
                li {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.filter-model {
    .text-box {
        padding: 15px 20px;
        min-width: 345px;
        display: inline-block;

        ul {
            margin-bottom: 30px;
        }

        h1 {
            text-align: left;
            font-family: "ITC-bold";
        }

        .model-close-btn {
            top: 14px;
        }
    }

    .radio-grp {
        margin-bottom: 12px;

        label {
            @include prefix(box-shadow, none);
            background-color: transparent;
            border-radius: 0;
            padding: 0px 25px 0 0;
            text-align: left;
            font-size: 14px;
            font-family: "ITC-reg";

            &::before {
                left: auto;
                right: 6px;
                top: 6px;
            }

            &::after {
                left: auto;
                right: 0;
                top: 0;
            }
        }
    }

    .add-btn {
        .btn-add {
            min-width: 148px;
            max-width: 100%;
            font-size: 16px;
            margin-right: 9px;
        }
    }
}

.order-detail {
    padding: 30px 0;

    .product-add-list {
        padding: 11px 20px;

        h4 {
            margin-bottom: 8px;
            color: $color2;
            font-family: "ITC-bold";
            font-size: 16px;
        }

        li {
            padding-bottom: 0;
            border: none;
            margin-bottom: 10px;

            .product-area {
                figure {
                    width: 37px;
                    height: 53px;
                }

                .product-add-info {
                    h4 {
                        width: 100%;
                        font-size: 14px;
                        font-family: "ITC-med";
                    }

                    .total-price {
                        .mx-2 {
                            margin: 3px 0;
                        }

                        h6 {
                            font-family: "ITC-reg";
                            font-size: 12px;
                            color: #717171;
                        }
                    }
                }
            }
        }
    }

    form {
        padding-bottom: 0;

        .shipping-bottom {
            margin-top: 30px;
        }

        .apply-box {
            margin-bottom: 20px;

            .add-boxs {
                h6 {
                    font-size: 14px;
                    line-height: 1;
                    color: #3e3e3e;
                    margin-bottom: 10px;
                    font-family: "ITC-med";
                }

                p {
                    font-size: 12px;
                    color: #414042b8;
                    font-family: "ITC-reg";
                }

                .date-box {
                    width: 50%;
                }

                .add-1 {
                    width: 50%;
                    margin-right: 20px;
                }
            }

            .driver-box {
                margin-top: 20px;
            }
        }

        .mprice-box {
            background-color: #ffffff;
            padding: 20px 20px;
            border-radius: 10px;
            -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
            -ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
            box-shadow: 0px 0px 10px rgb(0 0 0 / 24%);

            .total-box {
                h4 {
                    font-size: 14px;
                    line-height: 1;
                    color: #3e3e3e;
                    margin-bottom: 10px;
                    font-family: "ITC-med";
                }
            }

            .total-item-list {
                font-size: 12px;
                color: rgba(65, 64, 66, 0.7215686275);
                font-family: "ITC-reg";
                display: block;
                border-top: none;
                padding-top: 0;
            }

            &.order-price-box {
                margin-bottom: 0;
            }
        }
    }

    .not-delivered li::before {
        right: -54px !important;
    }

    .tracking-outer {
        padding: 10px 10px 0;
    }

    .tracking-box {
        @include prefix(box-shadow, 0px 0px 6px rgba(0, 0, 0, 0.24));
        background-color: $white;
        min-height: 62px;
        border-radius: 10px;
        margin-bottom: 20px;
        // display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        .tracking-list {
            text-align: center;
            max-width: 625px;
            margin: 0 auto;

            li {
                font-size: 12px;
                color: #717171;
                font-family: "ITC-reg";
                // margin-right: 25px;
                margin-right: 10px;
                display: inline-block;
                width: auto;
                position: relative;

                &:last-child {
                    margin-right: 0;
                }

                &::before {
                    content: "";
                    background-color: $color2;
                    height: 2px;
                    width: 90px;
                    display: inline-block;
                    position: absolute;
                    // right: -55px;
                    right: -41px;
                    top: 9px;
                }

                &:last-child::before {
                    display: none;
                }

                span {
                    width: 19px;
                    height: 19px;
                    margin: 0 auto 8px;
                    border: 1px solid $color2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: $white;
                    position: relative;
                    z-index: 11;

                    &::before {
                        content: "";
                        width: 9px;
                        height: 9px;
                        background-color: $color2;
                        position: absolute;
                        border-radius: 50%;
                        z-index: 1;
                    }

                    &.active {
                        background-color: $color2;

                        &::before {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    .scroll-boxs {
        overflow: hidden;

        .scrollbar-container {
            // height: 555px;
            height: calc(100vh - 160px);
            padding: 10px 20px 10px 10px;
        }

        &.cart-scroll {
            .scrollbar-container {
                // height: 555px;
                height: calc(100vh - 335px);
            }
        }

        &.order-scroll {
            .product-add-list {
                margin-bottom: 15px;
            }

            .scrollbar-container {
                height: calc(100vh - 225px);
            }
        }
    }
}

.map-section {
    .map-box {
        padding-top: 20px;

        iframe {
            height: 100vh;
        }
    }
}

// @media (min-width: 768px) {
//     .order-detail {
//         .scroll-boxs {
//             .scrollbar-container {
//                 height: 635px;
//             }
//         }
//     }
// }

@include responsive(s) {
    .order-list-section {
        .order-list-box {
            .add-btn {
                .btn-add {
                    min-width: 70px;
                    font-size: 12px;
                }
            }
        }
    }

    .filter-model {
        .text-box {
            min-width: 295px;
        }

        .add-btn {
            .btn-add {
                min-width: 120px;
                max-width: 100%;
                font-size: 16px;
                margin-right: 9px;
            }
        }
    }

    .order-detail {
        .tracking-box {
            .tracking-list {
                li {
                    margin-right: 7px;

                    &::before {
                        width: 73px;
                        right: -39px;
                    }
                }
            }
        }
    }
}

.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    // background-color: rgba(0, 116, 217, 1);
    background-color: $color2;
}

.new-tracking-bar {
    width: 85%;
}

.main-tracking {
    display: flex;
    align-items: flex-start !important;
    min-height: 100px !important;
    padding: 29px 10px 0 10px !important;
}

.label {
    margin-top: 60px;
    color: $color2;
    text-align: center;
    font-size: 12px;
    font-family: 'ITC-reg';
}

.tracking-web {
    padding: 24px 0px 24px 60px !important;
    min-height: 85px !important;
}