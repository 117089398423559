// Color Variable
$theme-color: #337091;
$white: #ffffff;
$black: #000000;

// Gray
$color1:#F7F7F7;
$color2:#414042;
$color3:#414042B8;
$color4:rgba(255, 255, 255, 0.72);
$color5:#3B3D46;
$color6:#414143;

$success: #089B55;
$danger: #E5664B;
$warning: #E5B64B;
$info: #448FB8;

// media quary
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$s: 374px;