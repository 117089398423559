.detail-section {
  padding: 11px 0;

  &.add-cart-section {
    padding-bottom: 80px;
  }

  .detail-list {
    margin-bottom: 25px;

    a,
    li {
      font-size: 14px;
      font-family: "ITC-reg";
      line-height: 1;
      color: rgba(65, 64, 66, 0.5);
      display: inline-block;
      margin-right: 10px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &:before {
        content: ">";
        color: rgba(65, 64, 66, 0.5);
        font-size: 13px;
        position: absolute;
        right: -9px;
      }

      &:last-child:before {
        display: none;
      }
    }
  }

  .details-box {
    text-align: center;
    border-bottom: 1px solid #80808042;
    padding-bottom: 20px;
    margin-bottom: 20px;

    h4 {
      color: $color2;
      margin-bottom: 10px;
      font-family: "ITC-bold";
      font-size: 1.8rem;
    }

    .price-list {
      margin-bottom: 10px;

      li {
        cursor: pointer;
        display: inline-block;
        border: 1.2px solid $color2;
        min-height: 92px;
        @include prefix(box-shadow, none);
        border-radius: 3px;
        padding: 10px 5px;
        text-align: center;
        margin-right: 8px;
        margin-bottom: 0;
        border-image-slice: 1;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          border-radius: 0;
          border-image-source: -moz-linear-gradient(90deg,
              rgba(243, 121, 40, 1) 0%,
              rgba(234, 25, 129, 1) 30%,
              rgba(134, 57, 157, 1) 70%,
              rgba(42, 96, 173, 1) 100%);
          border-image-source: -webkit-linear-gradient(90deg,
              rgba(243, 121, 40, 1) 0%,
              rgba(234, 25, 129, 1) 30%,
              rgba(134, 57, 157, 1) 70%,
              rgba(42, 96, 173, 1) 100%);
          border-image-source: linear-gradient(90deg,
              rgba(243, 121, 40, 1) 0%,
              rgba(234, 25, 129, 1) 30%,
              rgba(134, 57, 157, 1) 70%,
              rgba(42, 96, 173, 1) 100%);
        }

        h6 {
          margin-bottom: 6px;
          font-family: "ITC-med";
          font-size: 14px;
          line-height: 1;

          &:last-child {
            margin-bottom: 0;
          }
        }

        span,
        del {
          display: block;
          line-height: 1;
          font-family: "ITC-reg";
          font-size: 12px;
          margin-bottom: 6px;
          color: rgba(65, 64, 66, 0.7);
        }

        del {
          color: red;
        }
      }

      .active {
        border-radius: 0;
        border-width: 2.6px;
        border-image-source: -moz-linear-gradient(90deg,
            rgba(243, 121, 40, 1) 0%,
            rgba(234, 25, 129, 1) 30%,
            rgba(134, 57, 157, 1) 70%,
            rgba(42, 96, 173, 1) 100%);
        border-image-source: -webkit-linear-gradient(90deg,
            rgba(243, 121, 40, 1) 0%,
            rgba(234, 25, 129, 1) 30%,
            rgba(134, 57, 157, 1) 70%,
            rgba(42, 96, 173, 1) 100%);
        border-image-source: linear-gradient(90deg,
            rgba(243, 121, 40, 1) 0%,
            rgba(234, 25, 129, 1) 30%,
            rgba(134, 57, 157, 1) 70%,
            rgba(42, 96, 173, 1) 100%);
      }
    }

    .cart-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      z-index: 1;

      &.position-need {
        padding: 0 15px;
        margin-bottom: 0;
        max-width: 767px;
        width: 100%;
        background: #fff;
        bottom: 0;
        padding-bottom: 15px;
        padding-top: 30px;

        // &::after {
        //   content: "";
        //   position: absolute;
        //   height: 15px;
        //   background-color: #fff;
        //   width: 100%;
        //   bottom: 0;
        //   max-width: 100%;
        // }
      }

      p {
        font-size: 12px;
        color: #777678;
        margin-right: 8px;
        font-family: "ITC-med";

        span {
          color: #ff0000b8;
          display: inline-block;
          font-family: "ITC-med";
        }
      }

      .cart-number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .cart-btn {
          width: 100%;
          height: 47px;
          color: $white;
          border-radius: 3px;
          background-color: #414042;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer !important;
          font-size: 18px;
          font-family: "ITC-med";

          &.add-btn {
            width: 75%;
            margin-right: 15px;
          }
        }

        .input-btn {
          width: calc(100% - 50px);
          margin-left: 15px;
          text-align: right;
        }
      }
    }
  }

  .Product-Des {
    h5 {
      color: $color2;
      line-height: 1;
      margin-bottom: 10px;
      font-family: "ITC-med";
      font-size: 20px;
    }

    p {
      color: #414042b8;
      font-size: 14px;
      font-family: "ITC-reg";
      line-height: 1.5;

      ul {
        list-style: disc;
        list-style-position: inside;

        li {
          font-weight: bold;

          strong {
            font-weight: bold;
          }
        }
      }
    }
  }

  .rc-input-number-handler-delete {
    .rc-input-number-handler-down-inner {
      cursor: pointer;

      // &:after {
      //   content: "" !important;
      //   width: 22px;
      //   height: 15px;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   display: inline-block;
      //   padding-top: 28px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   text-align: center;
      //   margin: 0 auto;
      //   position: relative;
      //   top: 1px;
      // }
    }
  }

  .rc-input-number {
    height: 47px;
    width: auto;

    .rc-input-number-input {
      padding-left: 0;
      color: #0f0f0f !important;
    }

    .rc-input-number-handler {
      width: 47px;
      height: 47px;
      // line-height: 54px;
      padding: 0;
      border: 0;

      &.rc-input-number-handler-down {
        .rc-input-number-handler-down-inner {
          // &:after {
          //   font-size: 45px;
          //   font-weight: normal;
          //   line-height: 30px;
          //   line-height: 0;
          //   height: 45px;
          //   width: 45px;
          //   vertical-align: middle;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   position: relative;
          //   top: -3px;
          // }
        }
      }

      &.rc-input-number-handler-up {
        .rc-input-number-handler-up-inner {
          cursor: pointer;
          height: 100%;
          width: 100%;

          &:after {
            font-size: 45px;
            font-weight: normal;
            line-height: 1;
            position: static;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.silder-box {
  .swiper {
    width: 400px;
    height: 400px;
    border: 1px solid #80808042;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 25px;

    .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      .swiper-pagination-bullet-active {
        width: 13px;
        height: 5px;
        border-radius: 6px;
        background-color: #3b3d46;
      }
    }

    .swiper-wrapper {
      padding-bottom: 0;

      .swiper-slide {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;

        figure {
          display: inline-block;
          width: 100%;
          height: 100%;

          img {
            height: 100%;
            width: 100%;
            // object-fit: inherit;
            // object-fit: contain;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.rc-input-number-handler {
  .delete-lg {
    display: none;
  }
  &.rc-input-number-handler-delete {
    .minus-lg {
      display: none;
    }
    .delete-lg {
      display: inline-block;
    }
  }
}

@media(max-width:575px) {
  .detail-section {
    .details-box {
      .cart-box {
        .cart-number {
          .cart-btn {
            &.add-btn {
              width: calc(100% - 160px);
            }
          }
        }
      }
    }

    .rc-input-number {
      width: 140px;
    }
  }

  .silder-box {
    .swiper {
      width: 295px;
      height: 400px;
      // height:100%;
    }
  }
}

//customer review
.customer-reviews-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  // font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-family: "ITC-med";
  font-size: 20px;
  
}

.header-section {
  // text-align: center;
  margin-bottom: 20px;
  margin-left: 15px;
  font-family: "ITC-med";
  font-size: 20px;
  line-height: 1;
      // margin-bottom: 10px;
      // font-family: "ITC-med";
}

.header-section h2 {
  font-size: 24px;
  margin-bottom: 5px;
}

.header-section p {
  color: #555;
  margin-bottom: 10px;
  font-size: 14px;
}

.write-review-btn {
  background-color: #eaeef2;
  color: #0b0b0b;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  // top: 0;
  // right: 40px;
  /* Adjust position to ensure it's in the right place */
}

.write-review-btn:hover {
  background-color: #09090a;
  color: #f9f9f9;
}

.star-distribution {
  margin-bottom: 20px;
  width: 35%;
  margin-left: 20px;
  // margin: 0 auto;
}
@media (max-width: 768px) {
  .star-distribution {
    width: 90%; /* Set width to 100% for mobile devices */
    margin-bottom: 20px; /* Optional: adjust margin for mobile if needed */
  }
}
.star-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.star-bar span {
  width: 70px;
  font-size: 14px;
  color: #555;
}

.progress-bar {
  flex-grow: 1;
  height: 10px;
  background-color: #f3f6f3;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 10px;
}

.filled {
  height: 100%;
  background-color: #ffc107;
}

.percentage {
  width: 80px;
  font-size: 14px;
  text-align: right;
  color: #555;
}

.reviews-list {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-left: 20px;
}

.review-item {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  // margin-top: 10px;
}

// .user-picture {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   margin-right: 15px;
//   object-fit: cover;
// }
.user-initial {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eaeef2; /* Light background color for the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-size: 20px; /* Adjust size as needed */
  color: #0b0b0b; /* Text color */
  font-weight: bold;
  margin-bottom: 20px;
}
.user-initial span {
  display: inline-block;
  text-align: center;
}

.review-content {
  max-width: calc(100% - 65px);
  margin-bottom: 20px;
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px; /* Adds some space above the username */
}

.review-text {
  color: #333;
  font-size: 14px;
}
.stars {
  font-size: 24px; /* Adjust size as needed */
  // color: rgb(159, 159, 65); /* Yellow color for filled stars */
}

.star-container {
  display: inline-block;
  position: relative;
  font-size: 24px;
  color: lightgray; /* Light gray color for the background stars */
}
.star-container-review {
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: lightgray; /* Light gray color for the background stars */
}

.star-filled {
  color: #ffc107; /* Yellow color for filled stars */
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

// .product-section .rc-input-number-handler-delete .rc-input-number-handler-down-inner:after {
//   top: -5px;
// }

// product Review field


.review-form {
  // max-width: 500px;
  // margin: 0 auto;
  padding: 20px;
  // border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.review-form h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group select {
  margin-left: 10px; /* Space between "or" text and dropdown */
  padding: 5px; /* Add padding for better appearance */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Font size for consistency */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

.picture-preview {
  margin-top: 10px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.star-rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Adjust as needed */
}

.star-rating {
  display: flex;
  // flex-direction: row-reverse;
  // justify-content: center;
}

.star-rating button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}
@media (max-width: 375px) {
    .text-gradiant {
        font-size: 10px; /* Slightly smaller font for smaller screens */
    }

    .star-rating button {
        font-size: 16px; /* Smaller star size for smaller screens */
        // padding: 4px; /* Adjust spacing for smaller screens */
    }

    .star-rating .star {
        font-size: 16px; /* Adjusted star size for smaller screens */
    }
}

.star-rating .on .star {
  color: gold;
}

.star-rating .off .star {
  color: #ccc;
  transition: color 0.2s ease-in-out;
}

.star {
  font-size: 1.2rem;
  margin: 0 4px;
}

.submit-review-btn {
  margin-top: 20px; /* Adjust as needed */
  display: block;
  width: 100%; /* Full width button */
  max-width: 200px; /* Or set a fixed width */
  background-color: #4CAF50; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px; /* Button padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.submit-review-btn:hover {
  background-color: #010401; 
  color: #f9f9f9;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%; /* Ensures the modal takes up a maximum of 90% of the screen */
  margin: 0 auto; /* Centers the modal */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .modal {
    width: 90%; /* Modal width will adjust to 90% of the screen size */
    padding: 15px; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .modal-overlay {
    margin-top: 20px;
  }
  .modal {
    margin-top: 20px;
    width: 95%; /* Further reduce width for very small screens */
    padding: 10px; /* Further reduce padding */
  }
}

.close-modal {
  cursor: pointer;
  float: right;
  font-size: 24px;
}

.form-group {
  margin-bottom: 15px;
}

.picture-preview {
  max-width: 100px;
  margin-top: 10px;
  max-height: 50px;
}
.review-images {
  display: flex;
  gap: 10px;
}

.review-images img {
  width: 100px;
  height: auto;
  border-radius: 5px;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(156, 146, 146, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  
}

.image-modal-content {
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 40px;
}

.image-modal-image {
  max-width: 70vw;
  max-height: 70vh;
  object-fit: contain; /* Ensures the image scales within the modal while maintaining aspect ratio */
}
@media (max-width: 768px) {
  .image-modal-image {
    max-width: 100vw; /* For mobile devices, set image to 100% width */
    max-height: 100vh;
    object-fit: contain;
  }
}

@media (max-width: 480px) {
  .image-modal-image {
    max-width: 100vw; /* For very small screens (mobile), set to 70% of the viewport */
    max-height: 100vh;
    object-fit: contain;
  }
}

.image-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #080101;
  color: #f9f6f6;
  padding: 5px 10px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
}

.image-modal-close-button:hover {
  background-color: #dc0707;
  color: #fff;
}

// button {
//   display: block;
//   width: 100%;
//   padding: 10px;
//   background-color: #4caf50;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   font-size: 16px;
//   cursor: pointer;
// }

// button:hover {
//   background-color: #45a049;
// }
//pagination
.pagination {
  margin-top: 20px;
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.page-button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.page-button.active {
  background-color: #050b11;
  color: white;
  border-color: #007bff;
}
.rating-number {
  // margin-left: 10px; /* Space between stars and rating number */
  font-size: 17px;
  color: #333;
}
.list-product-star-container {
  display: flex; /* Align stars and rating text horizontally */
  align-items: center; /* Vertically center the text with the stars */
  margin-top: 0; /* Remove any top margin */
  padding: 0;
  margin-bottom: 5px;
}

.list-product-star-rating {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}

.list-product-star-filled {
  color: gold;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  // width: 70%; /* 3.5 stars out of 5 stars */
}

.list-product-star-empty {
  color: lightgray;
}

.rating-text {
  margin-top: 5px;
  font-size: 14px;
  font-family: "ITC-reg";
  color: black;
  margin-left: 8px; /* Space between stars and rating text */
}
