.auth-section {
    padding: 25px 16px 0;

    .back-arrow {
        margin-bottom: 33px;
        cursor: pointer;
    }

    form {
        text-align: center;

        .img-area {
            margin-bottom: 45px;
        }

        .info-box {
            height: calc(100vh - 380px);

            @include responsive(sm) {
                height: auto;
            }

            h1 {
                font-size: 24px;
                color: #545454;
                font-family: "ITC-bold";
                line-height: 1;
                margin-bottom: 12px;
            }

            p {
                color: #6a6a6a;
                font-size: 14px;
                margin: 0 auto 30px;
                font-family: "ITC-reg";
            }

            .input-list {
                margin-bottom: 15px;
            }
        }

        .small-info {
            color: #3b3d46;
            font-size: 14px;
            display: block;
            font-family: "ITC-reg";

            @include responsive(sm) {
                padding: 15px 0;
            }

            a {
                color: #414042;
                font-family: "ITC-med";
            }
        }
    }
}

.input-list {

    // margin-bottom: 23px;
    .check-boxs {
        text-align: left;

        label {
            font-size: 12px;
            color: #3b3d46;
            display: inline-block;
            font-family: ITC-reg;
            text-align: left;

            a {
                color: #000203;
                display: inline-block;
                font-family: ITC-med;
            }
        }
    }

    li {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }

        .form-grp {
            position: relative;

            .input-box {
                width: 100%;
                height: 45px;
                border-radius: 10px;
                font-size: 14px;
                font-family: "ITC-med";
                padding: 0 20px 0 40px;
            }

            &::before {
                content: "";
                width: 17px;
                height: 16px;
                display: block;
                background-repeat: no-repeat;
                position: absolute;
                top: 15px;
                left: 15px;
            }
        }

        .check-boxs {
            label {
                font-size: 12px;
                color: #3b3d46;
                display: inline-block;
                font-family: ITC-reg;
                text-align: left;

                a {
                    color: #000203;
                    display: inline-block;
                    font-family: ITC-med;
                }
            }
        }
    }
}

.account-section {
    .input-list {
        li {
            .form-grp {
                position: relative;
                text-align: left;

                &::before {
                    width: 18px;
                    height: 18px;
                }
            }

            .col-grp {
                vertical-align: top;
                width: 48%;
                display: inline-block;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0px;
                }
            }

            .input-box {
                padding: 0 20px 0 45px;
            }

            span {
                font-size: 12px;
                color: #e5664b;
                font-family: "ITC-reg";
                display: block;

                a {
                    font-family: "ITC-med";
                    display: inline-block;
                    color: #888888;
                }
            }

            .check-boxs {
                label {
                    font-size: 12px;
                    color: #3b3d46;
                    display: inline-block;
                    font-family: ITC-reg;
                    text-align: left;

                    a {
                        color: #000203;
                        display: inline-block;
                        font-family: ITC-med;
                    }
                }
            }
        }
    }

    .links-btn {
        min-width: 100%;
    }
}

.otp-section {
    .info-box {
        .otparea {
            margin-bottom: 35px;

            .otp-container {
                justify-content: center;
            }

            .otp-box {
                input {
                    display: block;
                    border-bottom: 1px solid #707070;
                    font-size: 36px;
                    color: $color2;
                    font-family: "ITC-med";
                }

                span {
                    margin-right: 25px;
                    display: inline-block;
                }
            }
        }

        h6 {
            font-size: 14px;
            font-family: "ITC-reg";
            color: #6a6a6a;
            margin: 0 auto 40px;
            letter-spacing: 0.28px;
            line-height: 1.5;

            a {
                display: inline-block;
                color: $color2;
            }
        }

        .input-list {
            margin-bottom: 30px;

            li {
                margin-right: 25px;
                display: inline-block;

                &:last-child {
                    margin-right: 0;
                }

                .form-grp {
                    &::before {
                        display: none;
                    }

                    .inpoubox {
                        border-bottom: 1px solid #707070;
                        width: 60px;
                        font-size: 36px;
                        font-family: "ITC-med";
                        text-align: center;
                        color: $color2;
                    }
                }
            }
        }

        .small-text {
            text-align: center;
            font-size: 14px;
            color: #6a6a6a;
            margin-bottom: 25px;
            font-family: "ITC-reg";
            display: inline-block;
            width: 100%;
        }

        .links-btn {
            margin: 10px
        }
    }

    .auth-header {
        margin-bottom: 35px;

        .back-arrow {
            margin-bottom: 0;
        }

        h1 {
            color: $color2;
            font-family: "ITC-bold";
            line-height: 1;
            width: 100%;
            text-align: center;
        }
    }
}

@include responsive(xs) {
    .account-section {
        .input-list {
            li {
                .col-grp {
                    width: 100%;
                    margin: 0 0 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@include responsive(s) {
    .account-section {
        .input-list {
            li {
                span {
                    font-size: 11px;
                }
            }
        }
    }
}