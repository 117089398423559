.payment-section {

    // &.return-section{
    //     .address_form {
    //         .payment-list {
    //             .add-radio-list{
    //             @include prefix(box-shadow, none);
    //             padding: 0;
    //             }
    //             .scrollbar-container{
    //             //@include prefix(box-shadow, none);
    //             }
    //         }
    //     }
    // }
    .address_form {
        .payment-list {
            min-height: calc(100vh - 255px);
            padding-bottom: 10px;

            .scrollbar-container {
                @include prefix(box-shadow, 0px 0px 10px rgba(0, 0, 0, 0.24));
                border-radius: 10px;
                padding: 17px 35px 17px 25px;
                height: calc(100vh - 400px);
            }

            .add-radio-list {
                height: auto;
                @include prefix(box-shadow, 0px 0px 10px rgba(0, 0, 0, 0.24));
                border-radius: 10px;
                padding: 17px 35px 17px 25px;

                li {
                    line-height: 1;
                    margin-bottom: 13px;
                    padding: 0;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .radio-grp {
                    label {
                        padding: 0px 25px 0 55px;
                        @include prefix(box-shadow, none);

                        &::before {
                            left: 24px;
                            top: 6px;
                        }

                        &::after {
                            left: 18px;
                            top: 0;
                        }

                        .add-box {
                            .add-title {
                                margin-bottom: 0;

                                h5 {
                                    font-size: 14px;
                                    font-family: "ITC-reg";
                                    line-height: 1;
                                    padding-top: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.payment-form {
            min-height: auto;

            .payment-list {
                min-height: auto;
            }
        }

        &.cls-list-open {
            padding-top: 150px;
        }
    }

    .instruction-box {
        .shadow-box {
            padding: 23px 20px 0 20px;
            margin-bottom: 0;
        }
        .shadow-box {
            background-color: #ffffff;
            padding: 23px 20px 15px;
            border-radius: 10px;
            margin-bottom: 20px;
            -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
            -ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
        }
        .payment-list{
            h6{
                 
                    font-size: 16px;
                    font-family: "ITC-med";
                    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
                    padding-bottom: 11px;
                    color: #414042;
                    width: 100%;
            }
            textarea{
                border: 1px solid rgba(112, 112, 112, 0.2);              
                  padding: 6px;
                  height: 100px !important;
                &::placeholder{
                    color: #000;
                }
            }
        }
    }

    .checkout-btn {
        margin-top: 20px;
    }
}

.card-add-section {
    .input-list {
        li {
            .form-grp {
                &::before {
                    display: none;
                }
            }
        }
    }

    .floating {
        .floating__input {
            padding: 7px 20px 0 15px;
        }

        .floating__label {
            &::before {
                left: 15px;
            }
        }
    }

    .add-btn {
        .btn-add {
            font-size: 18px;
        }
    }
}

.check-grp {
    position: relative;
    padding-left: 38px;

    label {
        color: $color2;
        font-family: "ITC-reg";
        display: inline-block;
        line-height: 23px;
        font-size: 14px;

        &::before {
            content: "";
            width: 11px;
            height: 5px;
            position: absolute;
            left: 15px;
            top: 7px;
            opacity: 0;
            border-left: 1px solid #000000;
            border-bottom: 1px solid #000000;
            transform: rotate(-50deg);
        }

        &::after {
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid #000000;
            position: absolute;
            left: 12px;
            top: 2px;
        }
    }

    input[type="checkbox"] {
        opacity: 0;
        position: absolute;

        &:checked {
            &+label::before {
                opacity: 1;
            }
        }
    }
}

.order-section {
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    // min-height: calc(100vh - 185px);
    .order-screen {
        background-color: $white;
        max-width: 350px;
        min-height: 450px;
        padding: 44px 38px;
        display: inline-block;
        text-align: center;
        border-radius: 11px;
        @include prefix(box-shadow, 0px 3px 10px #00000040);

        figure {
            width: 98px;
            height: 98px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #00d63a;
            margin: 0 auto 30px;
        }

        h2 {
            color: $color2;
            font-size: 20px;
            margin: 0 auto 10px;
            font-family: "ITC-bold";
            width: 73%;
        }

        p {
            font-family: "ITC-reg";
            font-size: 12px;
            color: #414042b8;
            width: 76%;
            margin: 0 auto 15px;
        }

        .success-btn {
            .btn-add {
                min-width: 100%;
                height: 47px;
                margin-right: 8px;
                font-size: 18px;
                color: $white;
                font-family: "ITC-med";
                display: inline-block;
                line-height: 28px;
                background-color: $color2;
                border-radius: 3px;
                border: 1px solid transparent;
                cursor: pointer;
                margin-bottom: 20px;
                @include prefix(transition, all 0.4s ease-in-out);

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: transparent;
                    border-color: $color2;
                    color: $color2;
                }

                &.del-btn {
                    border: 1px solid $color2;
                    background-color: $white;
                    color: $color2;

                    &:hover {
                        background-color: $color2;
                        color: $white;
                    }
                }
            }
        }
    }
}

.paymnet-list-section {
    padding: 30px 0 0;

    .paymnets-box {
        .pay-list {
            li {
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    background-color: #fff;
                    font-size: 14px;
                    font-family: "ITC-med";
                    color: $color2;
                    padding: 14px 20px;
                    min-height: 45px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    @include prefix(box-shadow, 0px 0px 6px #0000003d);
                    border-radius: 5px;

                    i {
                        display: inline-block;
                        height: 20px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

.edit-card-section {
    padding: 28px 0;
}

.card-list-section {
    padding: 30px 0;

    .card-list-box {
        .card-list {
            li {
                background-color: #fff;
                padding: 15px 20px;
                min-height: 165px;
                width: 100%;
                @include prefix(box-shadow, 0px 0px 10px #0000003d);
                border-radius: 15px;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                h3 {
                    color: #383838;
                    margin-bottom: 25px;
                    font-family: "ITC-bold";
                    font-size: 18px;
                }
            }

            .card-info {
                width: calc(100% - 70px);

                .small-box {
                    font-family: "ITC-reg";
                    margin-bottom: 25px;

                    span {
                        color: #929292;
                        font-size: 12px;
                    }

                    h4 {
                        color: $color2;
                        font-size: 14px;
                    }
                }

                .add-btn {
                    text-align: right;
                }
            }
        }
    }
}

@include responsive(s) {
    .card-list-section {
        .card-list-box {
            .card-list {
                li {
                    padding: 15px 10px;
                }

                .card-info {
                    .small-box {
                        h4 {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .add-btn {
            .btn-add {
                min-width: 95px;
            }
        }
    }
}

.success-box-model {
    .order-section {
        height: auto;

        .order-screen {
            background-color: transparent;
            max-width: 100%;
            min-height: 100%;
            @include prefix(box-shadow, none);
            padding: 0;

            h2 {
                width: 60%;
            };
            h3 {
                width: 100%;
            }
        }
    }

    .model-body {
        width: 345px;
        min-height: 409px;
    }
}