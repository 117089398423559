.promocode-section {
    padding-top: 30px;
    padding-bottom: 15px;

    h3 {
        font-family: "ITC-med";
    }

    .promocode-area {
        .promocode-list {
            li {
                border-radius: 10px;
                padding: 14px 12px;
                background-color: $white;
                margin-bottom: 20px;
                @include prefix (box-shadow, 0px 0px 10px rgba(0, 0, 0, 0.24));

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .code-top {
                border-bottom: 0.5px solid rgba(112, 112, 112, 0.31);
                padding-bottom: 13px;
                margin-bottom: 7px;

                .off-box {
                    width: 72px;
                    height: 68px;
                    border-radius: 7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    font-family: 'ITC-bold';

                    span {
                        font-size: 21px;
                        color: $white;
                    }

                    p {
                        font-size: 30px;
                        color: $white;
                    }
                }

                .promo-info {
                    width: calc(100% - 90px);

                    h4 {
                        font-size: 14px;
                        line-height: 1;
                        margin-bottom: 7px;
                        color: $color2;
                        font-family: 'ITC-med';
                    }

                    P {
                        color: #414042B8;
                        font-size: 12px;
                        font-family: 'ITC-reg';
                        word-break: break-all;
                    }
                }
            }

            .code-bottom {
                .form-grp {
                    width: 50%;

                    .copy-textbox {
                        width: 87px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        resize: none;
                        color: $color2;
                        font-size: 12px;
                        font-family: 'ITC-reg';
                        text-transform: uppercase;
                        border: 1px dashed $color2;
                        text-align: center;

                        &:disabled {
                            color: $color2 !important;
                            border-color: $color2 !important;
                        }
                    }
                }

                .copy-btn-box {
                    position: relative;

                    .copy-btn {
                        font-size: 14px;
                        line-height: 1;
                        color: $color2;
                        font-family: 'ITC-med';
                        background-color: transparent;
                        cursor: pointer;
                    }

                    span {
                        font-size: 14px;
                        line-height: 1;
                        color: $color2;
                        font-family: 'ITC-reg';
                        display: block;
                        position: absolute;
                        top: 4px;
                        left: -60px;
                    }
                }
            }

        }
    }
}

.blue {
    background-color: #00ACC1;
}

.yellow {
    background-color: #FFB300;
}

.red {
    background-color: #EE2749;
}

.green-dark {
    background-color: #00C186;
}

.notifications-section {
    .promocode-area {
        .promocode-list {
            .code-top {
                border: none;
                padding: 0;
                margin: 0;
            }

            .code-top {
                .promo-info {
                    h4 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}