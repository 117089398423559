.rc-input-number-input {
  font-family: 'ITC-med';
  color: #0f0f0f;
}

.product-section {
  padding: 15px 0 0;

  // &.mobile-banner{
  //   height: calc(100vh - 151px);
  // }
  &.banner-section {
    .product-list {
      padding-top: 110px;
    }

    .select-list {
      position: fixed;
      max-width: 767px;
      top: 105px;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      background-color: #fff;
    }
  }

  .product-list {
    padding: 20px 0px 0;
    background-color: $white;

    .list-box {
      overflow: hidden;

      .select-list {
        text-align: left;
        width: 100%;
        display: flex;
        overflow: auto;
        padding: 10px 5px 10px;
        margin-bottom: 10px;
        background-color: #fff;
        z-index: 111111;
        cursor: pointer;

        li {
          min-width: 137px;
          height: 30px;
          border: 1px solid $color2;
          display: inline-block;
          text-align: center;
          line-height: 28px;
          font-size: 13px;
          font-family: "ITC-med";
          border-radius: 3px;
          margin-right: 16px;
          @include prefix(transition, 0.4s all ease-in-out);

          &:last-child {
            margin-right: 0;
          }

          &.active,
          &:hover {
            background-color: $color2;
            color: $white;
          }
        }
      }

      .product-boxlist {
        // margin-bottom: 20px;
        // max-height: 100vh;
        //height: calc(100vh - 200px);
        overflow: auto;
        padding: 10px 10px 10px 15px;

        h3 {
          font-family: 'ITC-med';
        }

        li {
          background-color: $white;
          @include prefix(box-shadow, 0px 0px 10px #0000003e);
          padding: 15px 15px 15px 15px;
          min-height: 190px;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .product-img {
            width: 83px;
            // height: 120px;
            // height: 83px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              // object-fit: cover;
              object-fit: contain;
            }
          }
        }

        &.mobile-product-list {
          li {
            min-width: 68px;

            // .product-info { padding-left: 10px; }
            a {
              color: #0f0f0f;
              width: 100%;
              // background-color: $white;
              // @include prefix(box-shadow, 0px 0px 10px #0000003e);
              padding: 10px 13px;
              min-height: 190px;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;

              &.mobile-a {

                // padding: 0;
                // flex-direction: column;
                .product-img {
                  // width: 100px;
                  // height: 90px;
                  width: 100px;
                  // height: 75px;

                  img {
                    // object-fit: cover;
                    object-fit: contain;
                  }
                }
              }

              &:last-child {
                margin-bottom: 0;
              }

              .product-img {
                width: 83px;
                // height: 120px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .product-info {
            width: 100%;
            padding: 0 0 10px 13px;

            //justify-content: center;
            //align-items: center;
            .cart-box {
              p {
                height: auto;
              }
            }

            .price-list {
              margin-top: 5px;
              li {
                padding: 5px;
                width: 68px;
              }
            }
          }
        }
      }

      .product-info {
        text-align: left;
        width: calc(100% - 90px);

        h4 {
          color: $color2;
          margin-bottom: 10px;
          font-family: "ITC-bold";
          font-size: 16px;
        }

        .link {
          cursor: pointer;
        }

        .price-list {
          margin-bottom: 10px;

          li {
            cursor: pointer;
            display: inline-block;
            border: 1.2px solid $color2;
            min-height: 92px;
            @include prefix(box-shadow, none);
            border-radius: 3px;
            padding: 10px 5px;
            text-align: center;
            margin-right: 4px;
            margin-bottom: 0;
            border-image-slice: 1;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              border-radius: 0;
              border-image-source: -moz-linear-gradient(90deg,
                  rgba(243, 121, 40, 1) 0%,
                  rgba(234, 25, 129, 1) 30%,
                  rgba(134, 57, 157, 1) 70%,
                  rgba(42, 96, 173, 1) 100%);
              border-image-source: -webkit-linear-gradient(90deg,
                  rgba(243, 121, 40, 1) 0%,
                  rgba(234, 25, 129, 1) 30%,
                  rgba(134, 57, 157, 1) 70%,
                  rgba(42, 96, 173, 1) 100%);
              border-image-source: linear-gradient(90deg,
                  rgba(243, 121, 40, 1) 0%,
                  rgba(234, 25, 129, 1) 30%,
                  rgba(134, 57, 157, 1) 70%,
                  rgba(42, 96, 173, 1) 100%);
            }

            h6 {
              margin-bottom: 6px;
              font-family: "ITC-med";
              font-size: 14px;
              line-height: 1;

              &:last-child {
                margin-bottom: 0;
              }
            }

            span,
            del {
              display: block;
              line-height: 1;
              font-family: "ITC-reg";
              font-size: 12px;
              margin-bottom: 6px;
              color: rgba(65, 64, 66, 0.7);
            }

            del {
              color: red;
            }
          }

          .active {
            border-radius: 0;
            border-width: 2.6px;
            border-image-source: -moz-linear-gradient(90deg,
                rgba(243, 121, 40, 1) 0%,
                rgba(234, 25, 129, 1) 30%,
                rgba(134, 57, 157, 1) 70%,
                rgba(42, 96, 173, 1) 100%);
            border-image-source: -webkit-linear-gradient(90deg,
                rgba(243, 121, 40, 1) 0%,
                rgba(234, 25, 129, 1) 30%,
                rgba(134, 57, 157, 1) 70%,
                rgba(42, 96, 173, 1) 100%);
            border-image-source: linear-gradient(90deg,
                rgba(243, 121, 40, 1) 0%,
                rgba(234, 25, 129, 1) 30%,
                rgba(134, 57, 157, 1) 70%,
                rgba(42, 96, 173, 1) 100%);
          }
        }

        .cart-box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            // font-size: 12px;
            font-size: 14px;
            color: #777678;
            margin-right: 8px;
            margin-bottom: 0;

            span {
              color: #ff0000b8;
              display: inline-block;
              font-family: "ITC-med";
            }
          }

          .cart-btn {
            // min-width: 98px;
            min-width: 108px;
            cursor: pointer;
            height: 30px;
            font-family: "ITC-med";
            font-size: 14px;
            color: $white;
            border-radius: 3px;
            background-color: #414042;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-family: 'ITC-med';
            padding: 0 5px;
            // line-height: 30px;
            line-height: 34px;
          }
        }
      }
    }
  }

  .rc-input-number-handler-delete {
    .rc-input-number-handler-down-inner {
      cursor: pointer;

      // &:after {
      //   content: "" !important;
      //   width: 15px;
      //   height: 17px;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   display: inline-block;
      //   padding-top: 2px;
      //   position: relative;
      //   top: 1px;
      // }
    }
  }
}

.rc-input-number {
  display: inline-block;
  // width: 90px;
  width: 100px;
  // height: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 26px;
  vertical-align: middle;
  border: none;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;

  // &-focused {
  //   border-color: #1890ff;
  //   box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  // }
  &.disable-qty {
    pointer-events: non;

    // background-color: grey;
    .rc-input-number-handler {
      background-color: grey;
      border-color: gray;
    }

    .rc-input-number-input {
      color: #0f0f0f;
    }
  }

  &-out-of-range {
    input {
      color: red;
    }
  }

  &-handler {
    // display: block !important;
    // width: 25px;
    // height: 25px;
    // width: 30px;
    // height: 30px;
    // background-color: #414042;
    // border: 1px solid #414042;
    // border-radius: 3px;
    overflow: hidden;
    // line-height: 12px;
    text-align: center;
    touch-action: none;
    // line-height: 20px;
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;

    &-active {
      background: #ddd;
    }
    svg {
      display: inline-block;
      vertical-align: top;
    }
  }

  &-handler-up-inner,
  &-handler-down-inner {
    cursor: pointer;
    // display: block !important;
    // color: #666666;
    // font-style: normal;
    // -webkit-user-select: none;
    // user-select: none;
    height: 100%;
    width: 100%;
    display: inline-block;
  }

  // &:hover {
  //   border-color: #1890ff;

  //   .rc-input-number-handler-up,
  //   .rc-input-number-handler-wrap {
  //     border-color: #1890ff;
  //   }
  // }

  &-disabled:hover {
    border-color: #d9d9d9;

    .rc-input-number-handler-up,
    .rc-input-number-handler-wrap {
      border-color: #d9d9d9;
    }
  }

  &-input-wrap {
    height: 100%;
    overflow: hidden;
  }

  &-input {
    width: 100%;
    height: 100%;
    // padding-left: 15px;
    // font-family: 'ITC-reg';
    font-family: 'ITC-med';
    color: #0f0f0f;
    line-height: 26px;
    text-align: center;
    border: none;
    border-radius: 4px;
    outline: 0;
    transition: all 0.3s ease;
    transition: all 0.3s;
    -moz-appearance: textfield;
    font-size: 16px;

    &:disabled {
      background-color: transparent;
      color: #0f0f0f !important;
      opacity: 1;
    }
  }

  &-handler-wrap {
    display: block !important;
    //   width: 20px;
    //   height: 100%;
    //   border-left: 1px solid #d9d9d9;
    //   transition: all 0.3s;
  }

  &-handler-up {
    // display: block !important;
    // padding-top: 1px;
    color: $white;
    transition: all 0.3s;
    right: 0;

    // &-inner {
      // &:after {
        // content: "+";
        // color: $white;
        // font-size: 24px;
        // font-weight: bold;
        // position: relative;
        // top: -2px;
        // left: -1px;
        // width: 25px;
        // height: 25px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      // }
    // }
  }

  &-handler-down {
    display: block !important;
    transition: all 0.3s;
    left: 0;

    // &-inner {
      // &:after {
        // content: "-";
        // color: $white;
        // font-size: 27px;
        // line-height: 0;
        // font-weight: bold;
        // position: relative;
        /* height: 30px; */
        /* width: 30px; */
        // display: inline-block;
        /* top: 0px; */
        // vertical-align: middle;
        // top: 0;
      // }
    // }
  }

  &.handler-disabled {
    opacity: 0.3;

    &:hover {
      color: #999;
      border-color: #d9d9d9;
    }
  }

  // &-handler-down-disabled,
  // &-handler-up-disabled {
  //   .handler-disabled();
  // }

  &-disabled {
    .rc-input-number-input {
      pointer-events: none;
      background-color: #f3f3f3;
      cursor: not-allowed;
      color: #0f0f0f;
      // opacity: 0.72;
    }

    //   .rc-input-number-handler {
    //     .handler-disabled();
    //   }
  }
}

.mac {
  .product-section {
    .product-list {
      .list-box {
        .select-list {
          li {
            line-height: 31px;
          }
        }
      }
    }
  }
}

.btn-box.rm-pd-btn-box {
  padding-right: 0;
}

.rc-input-number-handler {
  .ic-delete {
    display: none;
  }
  &.rc-input-number-handler-delete {
    .ic-minus {
      display: none;
    }
    .ic-delete {
      display: inline-block;
    }
  }
}

@include responsive(lg) {
  .rc-input-number-input {
    padding-left: 0;
    color: #0f0f0f;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-product-list {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 48%;
      margin-right: 15px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(2) {
        margin-bottom: 0 !important;
      }
    }
  }

  .product-section {
    .product-list {
      .list-box {
        .product-info {
          .price-list {
            li {
              width: 66px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width:767px) {
  .product-section {
    .product-list {
      .list-box {
        .product-boxlist {
          &.mobile-product-list {
            .li-mobile {
              &:nth-child(2n) {
                margin-right: 0;
              }

              &:nth-last-child(2) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .product-section {
    .product-list {
      .list-box {
        .product-boxlist {
          &.mobile-product-list {
            display: flex;
            flex-wrap: wrap;

            li {
              overflow: hidden;
              padding: 0;
              width: 48%;
              min-width: unset;
              margin-right: 20px;

              a {
                &.mobile-a {

                  // padding: 0;
                  // flex-direction: column;
                  .product-img {
                    // width: 100%;
                    // height: 100%;
                    // img{
                    //   object-fit: cover;
                    // }
                  }
                }
              }
            }

            .product-info {
              width: 100%;

              //padding: 15px 10px;
              //justify-content: center;
              //align-items: center;
              .cart-box {
                p {
                  height: auto;
                }
              }

              .price-list {
                li {
                  padding: 5px;
                  width: 68px;
                }
              }
            }
          }
        }
      }
    }
  }

  .product-section .product-list .list-box .product-info .price-list li {
    margin-right: 6px;
    margin-bottom: 0;
    min-height: unset;
  }
}

@include responsive(xs) {
  .product-section {
    .product-list {
      .list-box {
        .product-boxlist {
          padding: 10px 15px;
        }
      }
    }

    .mobile-product-list {
      .list-box {
        .product-boxlist {
          padding: 10px 15px;
        }
      }
    }
  }

  .product-section {
    .product-list {
      .list-box {
        .product-boxlist {
          &.mobile-product-list {
            li {
              overflow: hidden;
              padding: 0;
              width: 100%;
              margin-right: 0;

              a {
                &.mobile-a {

                  // padding: 0;
                  // flex-direction: column;
                  .product-img {
                    // width: 100%;
                    // height: 100%;
                    // img{
                    //   object-fit: cover;
                    // }
                  }
                }
              }
            }

            .product-info {
              width: 100%;

              //padding: 15px 10px;
              //justify-content: center;
              //align-items: center;
              .cart-box {
                p {
                  height: auto;
                }
              }

              .price-list {
                li {
                  padding: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .product-section .product-list .list-box .product-info .price-list li {
    margin-right: 6px;
    margin-bottom: 0px;
  }

  .border-box {
    padding-top: 15px !important;
  }
}

@include responsive(s) {
  .product-section {
    .product-list {
      .list-box {
        .product-info {

          // width: calc(100% - 30px);
          .price-list {
            li {
              padding: 10px 3px;
              margin-right: 5px;

              h6 {
                font-size: 12px;
              }
            }
          }
        }

        .product-boxlist {
          li {
            padding: 15px 10px 15px 5px;
          }
        }
      }
    }

    &.mobile-product-list {
      li {
        a {
          padding: 15px 10px 15px 5px;
        }
      }
    }
  }
}

@media (max-width:359px) {

  .product-section {
    .product-list {
      .list-box {
        .product-boxlist {
          &.mobile-product-list {
            li {
              a {
                flex-direction: column;
                align-items: center;
                padding: 0;

                &.mobile-a {
                  .product-img {
                    width: 100%;
                    // height: 255px;
                    // height: 290px;
                  }

                  .product-info {
                    align-items: center;
                    padding: 15px 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}