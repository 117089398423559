* {
    margin:0px;
    padding:0px;
    border:none;
    outline:none;
    @include prefix (box-sizing, border-box);
    -webkit-tap-highlight-color: transparent;
}

html {
    @include prefix (text-size-adjust, none);
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

a {
    text-decoration: none;
    &:hover,&:focus {
        @extend a;
    }
}
button {
    outline: 0;

    &:hover, &:focus {
        @extend button;
    }
}

h1, h2, h3, h4, h5, h6, strong, b {
    margin: 0;
    font-weight: normal;
    line-height: normal;
}
.text-white {
    h1, h2, h3, h4, h5, h6, strong, b, p, ul, ol, li {
        color: $white;
    }
}
.text-gray {
    h1, h2, h3, h4, h5, h6, strong, b, p, ul, ol, li, .timer {
        color: $color1;
    }
}
p {
    margin: 0;
    line-height: normal;

}

ul,ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
svg {
    fill: currentColor;
    max-width: 100%;
    vertical-align: middle;
}
div,aside,figure,figcaption, label {
    margin: 0;
}
%transition {
    @include prefix( transition, all 0.3s ease-in-out);
}

input,textarea,select,header{
    @include prefix (appearance, none);
}
::-moz-selection {
    background: #389dab;
    color: $white;
}
::selection {
    background: #389dab;
    color: $white;
}
::-webkit-input-placeholder {color: #6A6A6A;font-size: 14px;font-family: 'ITC-reg';}
:-moz-placeholder { color: #6A6A6A;font-size: 14px;font-family: 'ITC-reg';}
::-moz-placeholder { color: #6A6A6A;font-size: 14px;font-family: 'ITC-reg';}
:-ms-input-placeholder {color: #6A6A6A;font-size: 14px;font-family: 'ITC-reg';}

// alert reset css

.swal-overlay--show-modal {
    z-index: 999999999;
    font-family: "ITC-bold";
    .swal-modal { font-family: "ITC-bold"; padding: 20px; }
    .swal-footer { font-family: "ITC-bold"; padding-bottom: 0; text-align: center; }
  }
  .swal-title{color: #1E1E1E; font-size: 15px; text-align: center; line-height: 32px; }
  .swal-text { font-family: "ITC-bold"; color: #1E1E1E; font-size: 21px; text-align: center; line-height: 32px; }
  .swal-icon { display: none; }
  .swal-button--confirm  { font-family: "ITC-bold"; background-color: #414042; color: #fff; border: none; box-shadow: none; border-radius: 5px; font-weight: 600; font-size: 14px; padding: 10px 24px; margin: 0; cursor: pointer; }
  .swal-button--confirm:hover { font-family: "ITC-bold"; background-color: #414042 !important; }
  
.leaflet-bottom {
    bottom: 0;
    display: none;
}
.cusrsor-pointer{
    cursor: pointer;
}

.zindex-999{
    z-index:99;
}
.text-pink{color:rgb(236, 165, 177)}
.text-blue{color:blue}
.text-green{color:#80b331;}
.text-maroon{color:rgb(180, 15, 15)}
.text-gradiant ,.detail-section .detail-list li::before{
    background: -moz-linear-gradient(
        90deg,
        rgba(243, 121, 40, 1) 0%,
        rgba(234, 25, 129, 1) 30%,
        rgba(134, 57, 157, 1) 70%,
        rgba(42, 96, 173, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(243, 121, 40, 1) 0%,
        rgba(234, 25, 129, 1) 30%,
        rgba(134, 57, 157, 1) 70%,
        rgba(42, 96, 173, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(243, 121, 40, 1) 0%,
        rgba(234, 25, 129, 1) 30%,
        rgba(134, 57, 157, 1) 70%,
        rgba(42, 96, 173, 1) 100%
    );
    @include prefix( background-clip, text);
    @include prefix( text-fill-color, transparent);
    -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-bold{
    font-weight: bold;
}
.copyright-text{
    font-family: 'ITC-bold';
    color:#555151
}