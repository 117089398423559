.contactus-section {
    padding: 0 15px;
    height: calc(100vh - 203px);
    .contactus-title {
        padding: 20px 0;
        font-family: "ITC-bold";
        text-align: center;
        border-bottom: 1px #dad0d0 solid;
        display: flex;
        flex-wrap: wrap;
        .back-arrow {
            cursor: pointer;
        }
        h2{
            margin-left: 20px;
        }
    }
    .contactus-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: "ITC-reg";
        padding: 20px 0;
        .contactus-row {
            display: flex;
            // justify-content: space-between;
            width: 100%;
            margin: 0 0 30px 0;
            align-items: center;
        }
        h4{
            font-size: 16px;
            font-family: "ITC-reg";
        }
        h3 {
            width: 200px;
            font-size: 16px;
            font-family: 'ITC-med';
        }
        .contactus-row-address {
            display: flex;
            width: 100%;
            margin: 0 0 30px 0;
        }
        .contact-text{
            width: 60%;
            display: inline-block;
            vertical-align: top;
        }
        .contact-small-title{
            width: 20%;
            display: inline-block;
            vertical-align: top;
        }
    }
}

@media (max-width:767px){
    .container{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .cls-hero-banner-home-page {
        padding:0 !important;
    }
    // .contactus-section {
    //     .contactus-content {
    //         .contact-small-title{
    //             width: 30%;
    //         }
    //         .contact-text{
    //             width: 70%;
    //         }
    //     }
    // }
}
@media (max-width:575px){
    .contactus-section {
        .contactus-content {
            .contact-small-title{
                width: 30%;
            }
            .contact-text{
                width: 70%;
            }
        }
    }
}
