.cms-section {
    // height: calc(100vh - 203px);
    height: calc(100vh - 245px);
    padding: 0 15px;
    overflow-y: auto;

    .cms-title {
        padding: 40px 0;
        font-family: "ITC-bold";
        text-align: center;
        border-bottom: 1px #dad0d0 solid;
        display: flex;
        flex-wrap: wrap;

        .back-arrow {
            cursor: pointer;
        }

        h2 {
            margin-left: 20px;
            width: calc(100% - 40px);
            text-align: left;
        }
    }

    .cms-content {
        font-family: "ITC-reg";
        padding: 20px 0;
        height: calc(100% - 84px);
        overflow-y: auto;
    }
}