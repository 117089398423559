.help-add-section{
    padding: 30px 0;
    .help-title{
        font-size: 28px;
        color: #000000;
        line-height: 1;
        font-family: "ITC-reg";
        margin-bottom: 20px;
    }
    .order-list-box {
        &.help-list-boxs{
            padding-bottom: 80px;
        }
        .order-list {
            padding-right: 5px;
            li {
                .order-title{
                    h6.red-dark{
                        color: #FF2753;
                    }
                    p{
                        font-size: 12px;
                        color: #717171;
                        line-height: 1;
                        font-family: "ITC-reg";
                    }
                }
                .order-info{
                    margin-bottom: 7px;
                    h4{
                        font-size: 16px;
                    }
                    .order-info-list{
                        li{
                            font-family: "ITC-reg";
                        }
                    }
                }
                .help-status{
                    margin-bottom: 0;
                }
            }
        }
        .scrollbar-container{
            height: calc(100vh - 140px);
            padding: 10px;
        }
    }
    .add-btn{
        .btn-add{
            font-size: 18px;
        }
        &.need-box{
            text-align: center;
            span{
                font-size: 14px;
                color: #3B3D46;
                font-family: "ITC-reg";
                a{
                    font-family: "ITC-med";
                    display: inline-block;
                    color: #3B3D46;
                }
            }
        }
    }
    .faq-box{
        h2{
            font-size: 20px;
            margin-bottom: 13px;
            font-family: "ITC-med";
            text-transform: capitalize;
            line-height: 1;
        }
    }
    .accordion{
        border:none;
        .accordion__item{
            background-color: $white;
            @include prefix (box-shadow,0px 0px 6px #0000003D);
            border-radius: 5px;
            padding: 14px 12px;
            min-height: 45px;
            margin-bottom: 23px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .accordion__button{
            background-color: transparent;
            padding: 0;
            position: relative;
            font-family: "ITC-med";
            font-size: 14px;
            color: $color2;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                border-color: #7D7D7D;
                @include prefix (transform,rotate(45deg));
            }
        }
        .accordion__panel{
            padding: 11px 15px 0 0;
            color: #414042B8;
            font-size: 12px;
            font-family: "ITC-reg";
        }
    }
}

.more-help-section{
    padding: 30px 0;
    .add_form {
        .input-list {
            li {
                .form-grp {
                    &.remove-icon{
                        &::before{
                            display: none;
                        }
                    }
                    textarea{
                        padding: 10px 20px 0 14px;
                        color: #6A6A6A;
                        font-family: 'ITC-reg';
                    }
                }
            }
        }
    }
    .add-btn{
        .btn-add{
            font-size: 18px;
        }
    }
}
.help-list-section{
    .order-list-box {
        .order-list {
            li {
                .order-info {
                    .order-info-list {
                        li {
                            span{
                                width: 77%;
                                vertical-align: top;
                            }
                        }
                    }
                }
            }
        }
    }
}
.red-dark{color: #FF2753;}

@media (min-width: 768px){
    .help-add-section {
        .order-list-box{
            padding: 10px;
            .scrollbar-container{
                height: calc(100vh - 140px);
            }
        }
    }
}
@include responsive(s){
    .help-add-section {
        .order-list-box {
            .order-list {
                li{
                    padding: 12px 9px;
                }
            }
        }
    }
}