.profile-box {
    padding: 20px 0;
    .input-list {
        min-height: calc(100vh - 218px);
    }
}
.profile-banner-section {
    padding: 15px 0;
    background-color: #F7F7F7;
    .btn-box {
        margin-bottom: 0;
    }
}
