.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-capitlize {
	text-transform: capitalize;
}

.text-success {
	color: $success;
}

.input-error {
	margin-top: 5px;

	span {
		display: block;
		font-family: 'ITC-reg';
	}
}

.text-danger {
	color: $danger;
}

.text-warning {
	color: $warning;
}

.text-info {
	color: $info;
}

.text-minute {
	color: $color2;
}

.position-relative {
	position: relative;
}

// Font Family

// Width css
.w-100 {
	width: 100%;
}

// Height SCSS
.min-h100 {
	min-height: 100vh;
}

.h-100 {
	height: 100%;
}

.app-main {
	max-width: 768px;
	margin: 0 auto;
	overflow: hidden;
	background-color: $white;

	// min-height: calc(100vh - 56px);
	.section-area {
		margin-top: 42px;
		overflow: hidden;

		// min-height: calc(100vh - 60px);
		&.add_section-area {
			margin-top: 55px;
			padding-bottom: 15px;
		}

		// &.section-main-payment{
		//     min-height: calc(100vh - 115px);
		// }
		&.section-main-product {
			min-height: calc(100vh - 156px);
		}
	}
}

.border-box {
	border-top: 1px solid rgba(65, 64, 66, 0.1);
	padding-top: 30px;
}

.wsection-area {
	// margin-top: 130px;
	margin-top: 88px;
	min-height: calc(100vh - 141px);

	&.add_section-area {
		margin-top: 79px;
	}
}

.home-page-header {
	margin-top: 157px;
}

.position-need {
	position: fixed;
	bottom: 15px;
	left: 0;
	right: 0;
	margin: auto;
}

.position-price {
	position: fixed;
	z-index: 111;
	width: 100%;
	max-width: 767px;
	top: 55px;
}

.padding-section {
	padding-top: 75px;
}

.fix-box {
	background-color: #fff;
	position: fixed;
	width: 100%;
	max-width: 768px;
	// bottom: 15px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 1111;
	padding: 5px 20px 15px;

	&.checkout-fix {
		bottom: 0;
		// bottom: 15px;
		padding: 10px 13px 10px;
	}

	.process-btn {
		&:hover {
			background-color: #414042 !important;
			color: #fff !important;
		}
	}
}

.container {
	width: 100%;
	max-width: 100%;
	padding-left: 50px;
	padding-right: 50px;
	margin: 0 auto;

	&.lg {
		width: 1140px;
	}

	&.md {
		width: 720px;
	}
}

.container-fluid {
	width: 100%;
	max-width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
}

.mob-add-new {
	font-size: 18px !important;
}

.no-msg {
	text-align: center;
	font-family: "ITC-med";
	font-size: 18px;
}

.order-list,
.promocode-list {
	h2 {
		font-family: 'ITC-med';
	}
}

[class^=" css-"][class$="-option"] {
	font-family: 'ITC-reg';
}

.faq-box {
	.accordion {
		h3 {
			font-family: 'ITC-med';
		}
	}
}

.cart-section .rc-input-number-handler {
	border-radius: 3px;
}

// Responsive
@include responsive(md) {

	// Fonts Size
	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 22px;

		&.big {
			font-size: 28px;
		}
	}

	h3 {
		font-size: 19px;
	}

	h4 {
		font-size: 17px;
	}

	h5 {
		font-size: 15px;
	}

	p {
		font-size: 15px;

		&.small {
			font-size: 13px;
		}
	}

	body {
		font-size: 15px;
	}
}

@media only screen and (max-width: 355px) {
	.product-section {
		.product-list {
			.list-box {
				.product-info {
					width: 100%;
					margin-top: 5px;
				}
			}
		}
	}

	.product-section {
		.product-list {
			.list-box {
				.product-boxlist {
					li {
						a {
							// flex-direction: column;
							padding: 0;
						}
					}
				}
			}
		}
	}

	.btn-box {
		justify-content: center;

		.outer-line {
			width: 118px;
		}
	}

	.cart-section {
		.product-add-list {
			li {
				.product-area {
					.product-add-info {
						h4 {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

.pac-container {
	z-index: 99999999999;
	position: fixed;
}

.Toastify__toast--info {
	background-color: white !important;
}

.Toastify__toast--info {
	background-color: $color2  !important;
	color: rgb(255, 255, 255) !important;
}

.toast-text {
	color: rgb(255, 255, 255) !important;
	font-family: 'ITC-reg';
}

.font-small {
	font-size: 12px;
}
.cls-m-search-header + section.section-area.add_section-area {
    margin-top: 0;
}