.address-section {
    &.edit-address {
        .add_form {
            height: 100%;
            padding-bottom: 65px;
        }
    }

    .address_form {
        padding-bottom: 115px;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        padding-top: 50px;

        &.add_adress_form {
            height: 100%;
            min-height: auto;
            padding-bottom: 75px;

            .add-silder {
                .scrollbar-container {
                    height: calc(100vh - 130px);
                }

            }
        }

        .no-data-message {
            margin-top: 40px;
            font-size: 18px;
            font-family: "ITC-bold";
            padding: 0 10px;
            text-align: center;
        }

        .add-radio-list {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            margin: 0 -10px;

            li {
                //margin-bottom: 20px;
                padding: 10px 13px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .add-box {
                .add-title {
                    margin-bottom: 10px;

                    h4 {
                        font-size: 16px;
                        font-family: "ITC-med";
                        color: $color2;
                    }

                    span {
                        font-size: 12px;
                        font-family: "ITC-reg";
                        color: #414042b8;
                    }
                }

                .add-info {
                    p {
                        font-size: 12px;
                        color: #414042b8;
                        margin-bottom: 11px;
                        font-family: "ITC-reg";
                    }
                }
            }
        }

        &.form-area {
            min-height: auto;

            .add-radio-list {
                // min-height: calc(100vh - 135px);
                padding-right: 5px;
            }

            .add-silder {
                .scrollbar-container {
                    height: calc(100vh - 155px);
                }
            }
        }

        &.shipping-form-area {
            min-height: auto;
            padding-bottom: 0;
            // padding-top: 70px;
            // padding-bottom: 140px;
            // .add-radio-list {
            //     min-height: calc(100vh - 240px);
            // }
        }

        .add-silder {
            overflow: hidden;

            &.cls-list-open {
                padding-top: 125px;
            }

            .scrollbar-container {
                // height: 440px;
                height: calc(100vh - 230px);
                padding: 10px;
            }

            &.setheight {
                .scrollbar-container {
                    height: calc(100vh - 395px);
                }
            }
        }
    }

    .payment-form {
        padding-top: 55px;
    }

    .shipping-form-area .fix-box {
        padding: 0 20px 8px;
    }

    .add-btn {
        .btn-add {
            // min-width: 98px;
            min-width: 80px;
            height: 30px;
            margin-right: 8px;
            font-size: 14px;
            // color: $color2;
            color: #414042;
            font-family: "ITC-med";
            display: inline-block;
            line-height: 28px;
            // background-color: $color2;
            background-color: transparent;
            border-radius: 3px;
            border: 1px solid $color2;
            cursor: pointer;
            @include prefix(transition, all 0.4s ease-in-out);

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background-color: $color2;
                border-color: $color2;
                color: white;
            }

            &.del-btn {
                border: 1px solid $color2;
                background-color: $white;
                color: $color2;

                &:hover {
                    background-color: $color2;
                    color: $white;
                }
            }
        }

        &.process-btn {
            .btn-add {
                height: 47px;
                min-width: 100%;
                margin-bottom: 20px;
                margin-right: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .add_form {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: auto;
        min-height: auto;
        padding-bottom: 15px !important;

        &.cls-list-open {
            padding-top: 140px;
        }

        .input-list {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: auto;
            padding-bottom: 50px;

            li {
                .form-grp {
                    .input-box {
                        height: 49px;
                    }

                    textarea {
                        padding: 18px 20px 0 45px;
                        min-height: 103px;
                        resize: none;
                    }
                }
            }
        }
    }

    &.add-padding-section {
        padding-top: 50px;
    }
}

.radio-grp {
    position: relative;

    label {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        background-color: $white;
        padding: 20px 25px 20px 55px;
        border-radius: 10px;
        @include prefix(box-shadow, 0px 0px 10px rgba(0, 0, 0, 0.24));

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color2;
            position: absolute;
            left: 24px;
            top: 26px;
            opacity: 0;
            @include prefix(transition, all 0.4s ease-in-out);
        }

        &::after {
            content: "";
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid $color2;
            position: absolute;
            left: 18px;
            top: 20px;
        }
    }

    input {
        opacity: 0;
        position: absolute;

        &:checked {
            &+label::before {
                opacity: 1;
            }

            &+label::after {
                content: "";
                border-color: $color2;
            }
        }
    }
}

.floating.form-grp.location .floating__label.area_label::before {
    transform: none !important;
    top: 3px;
    font-size: 12px;
}

.floating.form-grp.location .floating__label.area_label {
    top: 0px;
}

// floating label
.floating {
    text-align: left;
    border-radius: 10px;
    position: relative;
    width: 100%;
    // height: 45px;
    text-align: center;
    border: 1px solid transparent;
    border-image-slice: 1;
    margin: auto;

    .floating__input {
        @include prefix(transition, border-color 0.2s ease);
        width: 100%;
        height: 45px;
        border-radius: 10px;
        font-size: 14px;
        font-family: "ITC-med";
        padding: 20px 35px 0 45px;
        text-align: left;
        background: rgba(216, 216, 216, 0.16);
        border: 1px solid transparent;
        border-image-slice: 1;
        font-family: "ITC-reg";
        background-image: none !important;

        // &.pac-target-input {
        //     min-height: 103px;
        // }
        &:hover {
            border-radius: 0;
            -moz-border-image-source: -moz-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            -webkit-border-image-source: -webkit-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            -ms-border-image-source: -ms-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            -o-border-image-source: -o-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            border-image-source: linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
        }

        &:focus {
            border-radius: 0;
            -moz-border-image-source: -moz-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            -webkit-border-image-source: -webkit-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            -ms-border-image-source: -ms-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            -o-border-image-source: -o-linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
            border-image-source: linear-gradient(90deg,
                    rgba(243, 121, 40, 1) 0%,
                    rgba(234, 25, 129, 1) 30%,
                    rgba(134, 57, 157, 1) 70%,
                    rgba(42, 96, 173, 1) 100%);
        }
    }

    .floating__label.streetlabel::before{
        top: -54px;
    }
    .floating__label {
        display: block;
        position: absolute;
        max-height: 0;
        font-weight: 500;
        pointer-events: none;
        font-family: "ITC-reg";

        &::before {
            color: #6a6a6a;
            content: attr(data-content);
            display: inline-block;
            filter: blur(0);
            backface-visibility: hidden;
            @include prefix(transition, transform 0.2s ease);
            @include prefix(transform-origin, left top);
            left: 45px;
            top: 4px;
            position: relative;
            font-size: 14px;
            font-family: "ITC-reg";
        }

        &::after {
            bottom: 1rem;
            content: "";
            height: 0.1rem;
            position: absolute;
            transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
                background-color 0.3s ease;
            -webkit-transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
                background-color 0.3s ease;
            -ms-transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
                background-color 0.3s ease;
            -o-transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
                background-color 0.3s ease;
            -moz-transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
                background-color 0.3s ease;
            @include prefix(transform-origin, left top);
            opacity: 0;
            left: 0;
            top: 100%;
            margin-top: -0.1rem;
            transform: scale3d(0, 1, 1);
            width: 100%;
            background-color: transparent;
        }
    }

    .hidden--visually {
        border: 0;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        font-family: "ITC-reg";
    }

    .floating__input:focus+.floating__label::after {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }

    .floating__input:placeholder-shown+.floating__label::before {
        // transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
        font-family: "ITC-reg";
    }

    .floating__input__new:placeholder-shown+.floating__label::before {
        font-family: "ITC-reg";
    }

    textarea.floating__input:placeholder-shown+.floating__label::before {
        transform: translate3d(0, -94px, 0) scale3d(1, 1, 1);
        font-family: "ITC-reg";
    }

    .floating__label.area_label::before,
    textarea.floating__input:focus+.floating__label::before {
        transform: translate3d(0, -105px, 0) scale3d(0.82, 0.82, 1);
        font-family: "ITC-reg";
    }

    .floating__label::before,
    .floating__input:focus+.floating__label::before {
        transform: translate3d(0, -43px, 0) scale3d(0.82, 0.82, 1);
    }

    .floating__input:focus+.floating__label::before {
        color: #6a6a6a;
    }

    // &.active{
    //         border-radius: 0;
    //         border-image-source: linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: -webkit-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: -ms-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: -o-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //     }
    //     &:hover
    //     {
    //         border-radius: 0;
    //         border-image-source: -moz-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: -webkit-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: -ms-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //         border-image-source: -o-linear-gradient(
    //             90deg,
    //             rgba(243, 121, 40, 1) 0%,
    //             rgba(234, 25, 129, 1) 30%,
    //             rgba(134, 57, 157, 1) 70%,
    //             rgba(42, 96, 173, 1) 100%
    //         );
    //     }
}

@media (min-width: 768px) {
    .address-section {
        .address_form {
            .add-silder {
                .scrollbar-container {
                    height: calc(100vh - 245px);
                }

                &.setheight {
                    .scrollbar-container {
                        height: calc(100vh - 335px);
                    }
                }
            }
        }
    }
}

// floating
.input-list li {
    margin-bottom: 18px;
    position: relative;
}

.cls-close {
    display: block;
    width: 15px;
    position: absolute;
    left: auto;
    right: 10px;
    color: black !important;
    top: 15px;
}