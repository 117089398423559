.mheader-bar {
    padding: 15px 0;
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1111111;
    max-width: 768px;
    @include prefix(box-shadow, 0px 2px 2px #00000029);

    .cls-mheader-left {
        display: flex;
        align-items: center;
    }

    .left-box {
        .toggle {
            width: 25px;
            height: 18px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            cursor: pointer;
            margin-right: 15px;

            span {
                background-color: $color5;
                height: 2px;
                width: 25px;
                display: block;
                border-radius: 3px;
                position: absolute;
            }

            .top {
                top: 0;
            }

            .middle {
                top: 7px;
                width: 20px;
            }

            .bottom {
                top: 15px;
            }
        }

        .logo-box {
            display: inline-block;

            // vertical-align: middle;
            a {
                width: 80px;
                height: 30px;
                display: inline-block;
            }
        }

        .back-arrow {
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
        }

        h2 {
            display: inline-block;
            font-size: 20px;
            color: $color2;
            font-family: "ITC-med";
            line-height: 1;
        }
    }

    .right-box {
        position: relative;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 7px;

        i {
            display: inline-block;
            cursor: pointer;
            margin-right: 7px;
            font-size: 22px;

            &:last-child {
                margin-right: 0;
            }

            // &.users:hover ~ .drop-down {
            //     display: block;
            // }
            &.green-dots {
                height: 18px;
                position: relative;
                display: inline-block;
                font-size: 25px;

                &::before {
                    content: "";
                    background-color: #38d00b;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            &.cart {
                position: relative;

                span {
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    background-color: #717171;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "ITC-reg";
                    color: $white;
                    font-size: 14px;
                    font-style: normal;
                    position: absolute;
                    top: -9px;
                    right: -11px;
                }

                b {
                    font-style: normal;
                    font-size: 15px;
                    color: $color2;
                    font-family: "ITC-bold";
                    line-height: 1;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 5px;
                }
            }
        }

        .drop-down {
            position: absolute;
            top: 50px;
            right: 30px;
            display: none;
            z-index: 11111;

            .drop-downlist {
                min-width: 150px;
                min-height: 86px;
                z-index: 11111;
                display: inline-block;
                border-radius: 11px;
                background-color: #fff;
                position: relative;
                @include prefix(box-shadow, 0px 2px 2px #00000029);
                padding: 13px 5px;

                li {
                    margin-bottom: 10px;
                    line-height: 1;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.bottom-line {
                        border-bottom: 1px solid $color2;

                        a {
                            padding-bottom: 5px;
                        }
                    }

                    a {
                        font-size: 12px;
                        color: $color2;
                        font-family: "ITC-reg";
                        display: block;
                        padding-left: 25px;
                        text-transform: capitalize;

                        &::before {
                            content: "";
                            background-repeat: no-repeat;
                            width: 13px;
                            height: 13px;
                            display: block;
                            position: absolute;
                            left: 7px;
                        }
                    }
                }

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 13px solid transparent;
                    border-right: 13px solid transparent;
                    border-bottom: 22px solid $white;
                    position: absolute;
                    top: -21px;
                    right: 7px;
                }
            }
        }

        .active {
            display: block !important;
        }
    }

    .menu-main {
        display: flex;
        align-items: center;

        .nav-menu {
            .nav-item {
                color: #000000;
                position: relative;
                text-decoration: none;
                font-family: "Nunito", sans-serif;

                .cls-dropdown-sub {
                    li {
                        font-family: "Nunito", sans-serif;
                    }
                }
            }

            .nav-item:after {
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: "";
                display: block;
                height: 2px;
                left: 50%;
                position: absolute;
                background: #bb2193;
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                width: 0;
                bottom: -5px;
            }

            .nav-item:hover:after {
                width: 100%;
                left: 0;
            }
        }
    }

    .search-var-box {
        margin-top: 15px;
        position: relative;

        input.floating__input {
            transition: border-color 0.2s ease;
            width: 100%;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 35px 10px 15px;
            text-align: left;
            background: #ffffff;
            border: 1px solid #80808065;
            border-image-slice: 1;
            font-family: "ITC-reg";
        }

        button.search-btn {
            position: absolute;
            left: auto;
            right: 8px;
            top: 5px;
            background: transparent;
            font-size: 26px;
        }

        .search-reac {
            padding: 0px;
            width: 100%;

            li {
                padding: 10px;
                font-size: 14px;
                background: #f5f5f5;
                border-bottom: 1px solid #bb2193;
            }
        }
    }
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu .nav-item {
    font-size: 1rem;
    color: rgb(0, 0, 0);
}

@include responsive(lg) {
    .hamburger {
        display: flex;
        padding-right: 10px;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: start;
        top: 60px;
        left: 0;
        width: 100%;
        height: 100vh;
        transition: 0.4s;
        z-index: 2;
        background: rgb(0, 0, 0, 0.5);
        border: 1px solid #eee;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;

            .menu-nav-dropdown {
                background-color: #fff;
                width: 90%;
                height: 100%;

                li {
                    padding: 1rem 1rem;
                    width: 100%;
                    border-bottom: 1px solid #eee;

                    .nav-item {
                        font-size: 1rem;
                        font-family: "Nunito", sans-serif;
                        width: 100%;
                        display: block;

                        svg {
                            text-align: right;
                            float: right;
                        }
                    }

                    .cls-dropdown-sub {
                        display: none;

                        &.active {
                            display: block;

                            li {
                                padding: 8px 15px;
                                border-bottom: 1px solid #eee;

                                .nav-item {
                                    font-size: 1rem;
                                    color: #614ba8;
                                }

                                &:last-child {
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cls-m-search-header {
    padding-top: 53px;
    padding-left: 15px;
    padding-right: 15px;

    .search-var-box {
        margin-top: 15px;
        position: relative;

        input.floating__input {
            transition: border-color 0.2s ease;
            width: 100%;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 35px 10px 15px;
            text-align: left;
            background: #ffffff;
            border: 1px solid #80808065;
            border-image-slice: 1;
            font-family: "ITC-reg";
        }

        button.search-btn {
            position: absolute;
            left: auto;
            right: 7px;
            top: 4px;
            background: transparent;
            font-size: 23px;
        }

        .search-reac {
            padding: 0px;
            width: 100%;
            position: absolute;
            top: 39px;
            z-index: 9;

            li {
                padding: 10px;
                font-size: 14px;
                background: #f5f5f5;
                border-bottom: 1px solid #bb2193;
            }
        }
    }
}