// @import "../variable";

@mixin prefix($property, $parameters) {
  @each $prefix in -o-, -moz-, -ms-, -webkit-, "" {
    #{$prefix}#{$property}: $parameters;
  }
}

.loader-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loader {
  position: static;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  border: 8px solid white;
  border-radius: 5rem;
  border-top: 8px solid #42394b;
  animation: spin 2s linear infinite;
  @include prefix(transform, translate(-50%, -50%));
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
