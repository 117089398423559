.cart-section {
    .product-add-list {
        background-color: $white;
        padding: 23px 20px 10px;
        border-radius: 10px;

        .cart-remove {
            display: flex;
            justify-content: flex-end;

            h4 {
                text-align: right;
                padding-top: 3px;
                color: #e25f5f;
                font-size: 1rem;
                font-weight: 600;
                margin-left: 7px;
                font-family: 'ITC-reg';
            }
        }

        @include prefix(box-shadow, 0px 0px 10px rgba(0, 0, 0, 0.24));

        li {
            border-bottom: 1px solid rgba(112, 112, 112, 0.2);
            padding-bottom: 24px;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
                border: none;
                padding-bottom: 0;
            }

            .product-area {
                justify-content: space-between;

                figure {
                    width: 45px;
                    height: 63px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .product-add-info {
                    margin-left: 18px;
                    width: calc(100% - 70px);

                    h4 {
                        cursor: pointer;
                        color: $color2;
                        line-height: 1.5;
                        margin-bottom: 5px;
                        width: 90%;
                        font-size: 16px;
                        font-family: "ITC-bold";
                    }

                    .title-small {
                        color: $color2;
                        font-family: "ITC-med";
                        font-size: 14px;
                    }

                    .total-price {
                        h6 {
                            font-size: 14px;
                            color: $color2;
                            font-family: "ITC-med";
                            line-height: 1;
                        }
                    }
                }
            }
        }

        &.scroll-box {
            height: 330px;
            overflow: auto;
        }
    }

    .my-cart {
        margin-top: 55px;
    }

    form {
        padding-top: 50px;
        padding-bottom: 20px;

        &.cart-from {
            //height: calc(100vh - 240px);
            position: relative;
            padding-bottom: 80px;
        }

        // &.cart-scroll{
        //     height: calc(100vh - 330px);
        // }
        .apply-main-box {
            margin-top: 50px;
            //padding: 0 20px 20px;
            // position: absolute;
            // top: 0;
            // width: 100%;
            // left: 0;
        }

        .apply-box {
            background-color: $white;
            padding: 20px 20px;
            border-radius: 10px;
            // margin-bottom: 60px;
            @include prefix(box-shadow, 0px 0px 10px rgba(0, 0, 0, 0.24));

            h4 {
                font-size: 16px;
                color: $color2;
                font-family: "ITC-med";
                line-height: 1;
                margin-bottom: 15px;
            }

            .form-grp {
                position: relative;

                .input-box {
                    background-color: rgba(216, 216, 216, 0.16);
                    width: 100%;
                    height: 45px;
                    border-radius: 10px;
                    font-family: "ITC-reg";
                    padding: 0 70px 0 20px;
                }

                .apply-btn {
                    background-color: transparent;
                    font-size: 14px;
                    color: $color2;
                    font-family: "ITC-med";
                    cursor: pointer;
                    position: absolute;
                    top: 15px;
                    right: 25px;
                    z-index: 1;
                }

                .applied-promocode {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 6px 0px;
                    padding: 4px 2px 8px 2px;
                    border-bottom: 0.5px solid rgba(112, 112, 112, 0.31);

                    .remove-text {
                        color: #78767a;
                        font-size: 15px !important;
                    }

                    .remove-btn {
                        cursor: pointer;
                        font-size: 16px !important;
                        color: red;
                    }
                }
            }
        }

        .form-btn {
            .process-btn {
                // background-color: $color2;
                background-color: transparent;
                width: 100%;
                height: 47px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                font-size: 18px;
                border: 1px solid $color2;
                border-radius: 3px;
                cursor: pointer;
                // color: $white;
                color: $color2;
                font-family: "ITC-med";
                @include prefix(transition, all 0.4s ease-in-out);
                margin-left: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &.clear-btn {
                    color: $color2;
                    background-color: transparent;

                    &:hover {
                        background-color: $color2;
                        color: $white;
                    }
                }

                &:hover {
                    // background-color: transparent;
                    // color: $color2;
                    background-color: $color2;
                    color: white;
                }
            }
        }
    }

    .rc-input-number-handler-delete {
        .rc-input-number-handler-down-inner {
            cursor: pointer;

            // &:after {
            //     content: "" !important;
            //     width: 15px;
            //     height: 17px;
            //     background-position: center;
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     display: inline-block;
            //     padding-top: 2px;
            //     position: relative;
            //     top: 0.5px;
            // }
        }
    }

    .rc-input-number-handler {
        background-color: $color2;
    }

    .cart-imgbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 250px);
        margin-bottom: 15px;
    }

    .info-cart {
        p {
            color: rgba(65, 64, 66, 0.52);
            margin: 0 auto 13px;
            text-align: center;
            font-family: "ITC-reg";
        }
    }

    .scroll-box {
        padding-top: 15px;
        //overflow: hidden;
        //padding-top: 120px;
        &.scroll-login {
            padding-top: 0;
        }
        &.cls-list-open {
            padding-top: 125px !important;
        }


        .scrollbar-container {
            height: calc(100vh - 380px);
            padding: 10px 10px 5px 10px;
        }

        // &.box-scroll{
        //     .scrollbar-container {
        //         height: calc(100vh - 460px);
        //     }
        // }
    }
}

.price-box {
    background-color: $color1;
    padding: 15px 15px;
    margin-bottom: 20px;
    position: fixed;
    max-width: 768px;
    width: 100%;
    z-index: 99999; 
    top: 54px;
    .total-box {
        h4 {
            font-size: 16px;
            color: $color2;
            font-family: "ITC-med";
            margin-right: 5px;

            span {
                font-size: 12px;
                color: $color2;
                font-family: "ITC-reg";
                display: inline-block;
            }
        }

        h6 {
            font-size: 14px;
            color: $color2;
            font-family: "ITC-med";
            line-height: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            i {
                display: inline-block;
                line-height: 1;
                cursor: pointer;

                svg {
                    margin-left: 5px;
                }
            }
        }
    }

    .total-item-list {
        border-top: 0.5px solid rgba(112, 112, 112, 0.31);
        padding-top: 10px;
        margin-top: 14px;
        display: none;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
                border-top: 0.5px solid rgba(112, 112, 112, 0.31);
                padding-top: 10px;

                span {
                    font-family: "ITC-bold";
                    color: #3b3d46;
                }
            }

            span {
                color: #3b3d46;
                display: inline-block;
                line-height: 1;
                font-size: 12px;
                font-family: "ITC-med";
            }
        }

        &.list-open {
            display: block;
        }
    }

    &.position-box {
        max-width: 768px;
        margin: auto;
        position: fixed;
        top: 55px;
        left: 0;
        right: 0;
        z-index: 111;
    }
}

.cart-model {

    .text-box {
        max-width: 370px;
        min-height: 200px;
        padding: 32px 45px;
        border-radius: 10px;
        background-color: $white;
        position: relative;
        text-align: center;
        @include prefix(box-shadow, 0px 3px 10px #00000040);

        h1 {
            font-size: 24px;
            font-family: "ITC-hvy";
            color: #1e1e1e;
            line-height: 1;
            margin-bottom: 20px;
        }

        p {
            font-family: "ITC-reg";
            font-size: 15px;
            color: rgba(65, 64, 66, 0.52);
            margin-bottom: 10px;
        }

        .model-close-btn {
            background-color: transparent;
            left: unset;
            top: 16px;
            right: 16px;
        }
        figure {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #00d63a;
            margin: 0 auto 30px;
        }
    }

    &.model-open {
        display: flex;
    }

    .add-btn {
        .btn-add {
            min-width: 120px;
            height: 47px;
            margin-right: 10px;
            font-size: 18px;
            color: $white;
            font-family: "ITC-med";
            display: inline-block;
            line-height: 45px;
            background-color: $color2;
            border-radius: 3px;
            border: 1px solid transparent;
            cursor: pointer;
            margin-bottom: 0px;
            @include prefix(transition, all 0.4s ease-in-out);

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background-color: transparent;
                border-color: $color2;
                color: $color2;
            }

            &.del-btn {
                border: 1px solid $color2;
                background-color: $white;
                color: $color2;

                &:hover {
                    background-color: $color2;
                    color: $white;
                }
            }
        }
    }
}

.cancel-model {
    .text-box {
        h1 {
            font-family: "ITC-bold";
        }
    }
}

.close-btns {
    cursor: pointer;
    background-color: transparent;
    color: red;
    width: 20px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    font-size: 20px;
    font-family: "ITC-med";
    display: inline-block;
    margin-right: 10px;
    // @include prefix(transform, rotate(38deg));
}

.mac {
    .cart-section {
        form {
            .form-btn {
                .process-btn {
                    line-height: 2.7;
                }
            }
        }
    }
}

// @media (min-width: 768px) {
//     .cart-section {
//         .scroll-box {
//             .scrollbar-container {
//                 height: 565px;
//             }
//         }
//     }
// }
@include responsive(s) {
    .cart-section {
        .product-add-list {
            padding: 23px 10px;

            li {
                .product-area {
                    .product-add-info {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .cart-model {
        .text-box {
            padding: 32px 20px;
        }
    }
}